<template>
  <nav dir="rtl" class="bg-[#50B498] p-1 relative z-50">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8 relative">
      <div class="flex  h-16 items-center">
        <div class="flex items-center w-full">
          <!-- Texte pour Desktop et Mobile -->
          <span class="custom-text  lg:w-4/12">
            <router-link :to="{ name: 'home' }">
              متجر العلمي للملابس التقليدية
            </router-link>
          </span>
          <!-- Menu Desktop -->
          <div class="hidden md:flex md:items-center md:justify-between w-full">
            <!-- Centered Dropdown Buttons -->

            <div class="flex-grow flex justify-center space-x-4">
              <!-- Dropdown Produits -->
              <div class="relative">
                <button @click="toggleDropdownC" class="custom" :aria-expanded="isDropdownOpenC">
                  منتوجاتنا
                  <svg class="w-2.5 h-2.5 ms-1 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 4 4 4-4" />
                  </svg>
                </button>
                <div v-if="isDropdownOpenC"
                  class="absolute z-10 bg-white rounded-lg shadow-lg py-2 mt-2 top-full left-0 w-[200px]">
                  <!-- Dropdown Content for Mobile -->
                  <div class="flex flex-wrap">
                    <div v-for="category in categories" :key="category.id_categorie"
                      class="custom block w-full text-right">
                      <router-link :to="{
                        name: 'ShowSousCategory',
                        params: { id: category.id_categorie },
                      }" class="paque-text  block px-4 py-2 text-xl hover:bg-white hover:text-gray-900" role="menuitem"
                        @click="closeDropdownC">
                        {{ category.libelle }}
                      </router-link>
                    </div>
                    <router-link :to="{ name: 'Category' }"
                      class="paque-text lg:mr-16  p-2 block px-4 py-2 text-sm hover:bg-white hover:text-gray-900"
                      @click="closeDropdownC">
                      <p>المزيد</p>
                    </router-link>
                  </div>
                </div>
                <!-- Dropdown Menu (unchanged) -->
                <!-- ... -->
              </div>

              <!-- Dropdown Offers -->
              <div class="relative">
                <button @click="toggleDropdownP" class="custom w-[200px]" :aria-expanded="isDropdownOpenP">
                  عروضنا
                  <svg class="w-2.5 h-2.5 ms-1 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="m1 1 4 4 4-4" />
                  </svg>
                </button>
                <!-- Dropdown Menu (unchanged) -->
                <div v-if="isDropdownOpenP"
                  class="absolute z-10 bg-white rounded-lg shadow-lg py-2 mt-2 top-full left-0 w-[200px]">
                  <!-- Dropdown Content for Mobile -->
                  <div class="flex flex-wrap">
                    <div v-for="paquet in paques" :key="paquet.id_paquet" class="block w-full text-right">
                      <router-link :to="{ name: 'Paquedetaille', params: { id: paquet.id_paquet } }"
                        class="paque-text  block px-4 py-2 text-xl hover:bg-white hover:text-gray-900" role="menuitem"
                        @click="closeDropdownP">
                        {{ paquet.libelle }}
                      </router-link>
                    </div>
                    <router-link :to="{ name: 'ShowPaque' }"
                      class="paque-text p-2 lg:mr-16 hover:bg-white hover:text-gray-900"
                      @click="closeDropdownP">
                      <p>المزيد</p>
                    </router-link>
                  </div>
                </div>
                <!-- ... -->
              </div>

              <div v-if="isAdmin" class="relative flex items-center">
                <router-link :to="{ name: 'ShowCommandes' }" class="custom" role="menuitem">
                  الطلبات
                </router-link>
              </div>
            </div>

            <!-- Cart aligned to the end -->
            <div @click="cart.toggleCart" class="flex-shrink-0">
              <a href="#" class="group -m-2 flex items-center p-2">
                <span class="custom-count ml-2 text-sm font-medium text-gray-700 group-hover:">{{ cart.count }}</span>
                <font-awesome-icon icon="shopping-cart" class="text-white" />
                <span class="sr-only">items in cart, view bag</span>
              </a>
            </div>
          </div>

          <CartList :show="cart.showCart" />
        </div>

        <!-- Mobile Menu Button -->
        <div class="flex md:hidden items-center">
          <div @click="cart.toggleCart" class="flex-shrink-0 ml-4">
            <a href="#" class="group -m-2 flex items-center p-2">
              <span class="custom-count ml-2 text-sm font-medium text-gray-700 group-hover:">{{ cart.count }}</span>
              <font-awesome-icon icon="shopping-cart" class="text-white" />
              <span class="sr-only">items in cart, view bag</span>
            </a>
          </div>
          <CartList :show="cart.showCart" />
          <button @click="toggleMenu" type="button"
            class="bg-gray-100 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg v-if="!isMenuOpen" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg v-else class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile Menu -->
    <div v-if="isMenuOpen" class="md:hidden" id="mobile-menu">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <!-- Add Mobile Dropdown Buttons for Products and Offers -->
        <div class="relative">
          <button @click="toggleDropdownC" class="custom w-full  text-center py-2">
            منتوجاتنا
            <svg class="w-2.5 h-2.5 ms-1 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>
          <div v-if="isDropdownOpenC"
            class="absolute z-10 bg-white rounded-lg shadow-lg py-2 mt-2 top-full left-0 w-[300px]">
            <!-- Dropdown Content for Mobile -->
            <div class="flex flex-wrap">
              <div v-for="category in categories" :key="category.id_categorie" class="custom block w-full text-right">
                <router-link :to="{
                  name: 'ShowSousCategory',
                  params: { id: category.id_categorie },
                }" class="paque-text block px-4 py-2 text-sm hover:bg-white hover:text-gray-900" role="menuitem"
                  @click="closeDropdownC">
                  {{ category.libelle }}
                </router-link>
              </div>
              <router-link :to="{ name: 'Category' }"
                class="paque-text p-2 block w-full text-center hover:bg-white hover:text-gray-900"
                @click="closeDropdownC">
                <p>المزيد</p>
              </router-link>
            </div>
          </div>
        </div>

        <div class="relative">
          <button @click="toggleDropdownP" class="custom w-full  text-center py-2">
            عروضنا
            <svg class="w-2.5 h-2.5 ms-1 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </button>
          <div v-if="isDropdownOpenP"
            class="absolute z-10 bg-white rounded-lg shadow-lg py-2 mt-2 top-full left-0 w-[300px]">
            <!-- Dropdown Content for Mobile -->
            <div class="flex flex-wrap">
              <div v-for="paquet in paques" :key="paquet.id_paquet" class="block w-full text-right">
                <router-link :to="{ name: 'Paquedetaille', params: { id: paquet.id_paquet } }"
                  class="paque-text px-4 py-4 hover:bg-white hover:text-gray-900" role="menuitem"
                  @click="closeDropdownP">
                  {{ paquet.libelle }}
                </router-link>
              </div>
              <router-link :to="{ name: 'ShowPaque' }"
                class="paque-text p-2 block w-full text-center hover:bg-white hover:text-gray-900"
                @click="closeDropdownP">
                <p>المزيد</p>
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="isAdmin" class="relative flex items-center">
          <router-link :to="{ name: 'ShowCommandes' }" class="custom w-full text-center py-2" role="menuitem">
            الطلبات
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import CartList from '@/components/CartList.vue';
import { useCartStore } from '@/useCartStore';
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  components: {
    CartList, // Inclusion du composant CartList
  },
  data() {
    return {
      isMenuOpen: false,
      isTextRight: true,
      categories: [],
      paques: [],
      isLoading: false,
      error: null,
      isDropdownOpenC: false,
      isDropdownOpenP: false,
      cart: useCartStore(), // Utilisation du store Pinia
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  mounted() {
    this.getCategoriesName();
    this.getPaquesName();
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleTextAlign() {
      this.isTextRight = !this.isTextRight;
    },
    toggleDropdownC(event) {
      this.isDropdownOpenC = !this.isDropdownOpenC;
      event.stopPropagation();
    },
    toggleDropdownP(event) {
      this.isDropdownOpenP = !this.isDropdownOpenP;
      event.stopPropagation();
    },
    closeDropdownC() {
      this.isDropdownOpenC = false;
    },
    closeDropdownP() {
      this.isDropdownOpenP = false;
    },
    handleOutsideClick(event) {
      const dropdownMenuC = this.$refs.dropdownMenuC;
      const dropdownMenuP = this.$refs.dropdownMenuP;

      if (dropdownMenuC && !dropdownMenuC.contains(event.target)) {
        this.closeDropdownC();
      }
      if (dropdownMenuP && !dropdownMenuP.contains(event.target)) {
        this.closeDropdownP();
      }
    },
    async getCategoriesName() {
      const url = `showcategoriesnames`;
      this.isLoading = true;
      try {
        const response = await axios.get(url);
        this.categories = response.data?.data || [];
      } catch (error) {
        this.$logErrorToBackend("error: ",error.message);
        this.error = "حدث حطأ أثناء تحميل الصفحة المرجو إعاد المحاولة";
      } finally {
        this.isLoading = false;
      }
    },
    async getPaquesName() {
      const url = `showpaquesnames`;
      this.isLoading = true;
      try {
        const response = await axios.get(url);
        this.paques = response.data?.data || [];
      } catch (error) {
        this.$logErrorToBackend("error: ",error.message);
        this.error = "حدث حطأ أثناء تحميل الصفحة المرجو إعاد المحاولة";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.custom-text {
  font-family: 'Arabswell', serif;
  color: #ffffff;
  /* background: linear-gradient(135deg, #f2E318 0%, #f2E318 100%); */
  /* padding: 10px 30px;
  border-radius: 15px; */
  font-size: larger;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom {
  font-family: 'Arabswell', serif;
  color: #ffffff;
  font-size: large;
  font-weight: bold;

}
.custom-count{
  font-family: 'Arabswell', serif;
  color: #ffffff;
  font-size: small;
  font-weight: bold;

}

.paque-text {
  font-family: 'Arabswell', serif;
  color: #50B498;
  font-weight: bold;
  font-size: large;

}

/* Ajoutez vos styles personnalisés ici si nécessaire */
</style>
