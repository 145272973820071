<template>
  <div class="category-box">
    <div class="swiper-container">
      <Swiper :effect="'cube'" :grabCursor="true" :cubeEffect="{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }" :pagination="{ clickable: true }" :navigation="true" :modules="swiperModules" :autoplay="{
        delay: 1500,
        disableOnInteraction: false,
      }" class="mySwiper">
        <SwiperSlide v-for="(image, index) in product.images" :key="index">
          <img :src="`${image}`" class="w-full h-full object-cover">
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="p-6">
      <h4 class="custom text-xl font-semibold  mb-2">{{ product.libelle }}</h4>
      <p class="custom text-2xl font-bold text-green-600"> {{ product.prix }} درهم </p>
      <router-link type="button" class="btn text-white bg-[#50B498]"
        :to="{ name: 'ProductDetaille', params: { id: product.id_produit } }">
        التفاصيل
      </router-link>

      <button v-if="isAdmin" type="button" class="btn btn-danger"
        @click="deleteproduit(id_paquet, product.id_produit)">Supprimer</button>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCube, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { mapGetters } from 'vuex'
import axiosInstance from '@/Axiosauth';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

export default {
  name: "ProductCard",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    id_paquet: {
      required: true
    }
  },
  data() {
    return {
      swiperModules: [EffectCube, Pagination, Navigation, Autoplay],
      currentIndex: 0,
      VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  methods: {


    async deleteproduit(id_paquet, id_produit) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce produit ?")) {
        try {
          await axiosInstance.delete(`contenus-paquet/${id_paquet}/${id_produit}`);
          this.$emit('deletedproduitId', id_produit);
          alert("Produit supprimée avec succès !");
        } catch (error) {
          this.$logErrorToBackend("Error lors de la suppresion de produit",error.message);
          alert("Erreur lors de la suppression de produit.");
        }
      }
    },
  }
};
</script>

<style scoped>
.produit_image {
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
}

.category-box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.category-box:hover {
  transform: translateY(-5px);
}

.swiper-container {
  height: 250px;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 1.7rem);
  color: #0c3a14;
}
</style>
