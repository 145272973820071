import { defineStore, acceptHMRUpdate } from 'pinia';

export const useCartStore = defineStore('shopping-cart', {
  state: () => ({
    items: [],
    showCart: false, // New state to control CartList visibility
  }),
  persist: true,
  getters: {
    count:(state)=>state.items.reduce((prevQty,nextItem)=>prevQty +nextItem.quantite ,0),
    
      total:(state)=>state.items.reduce((prevprix,nextItem)=>prevprix + (nextItem.quantite)*(nextItem.prix) ,0),
    },

  actions: {
    add(newItem, quantite = 1,ispaquet=false) {
      // Find the item in the cart by its id
      const existingItem = this.items.find(item => item.id_produit === newItem.id_produit);
      
      if (existingItem) {
        // If the item exists, update its quantity
        existingItem.quantite += quantite;
      } else {
        // Add the new item with the specified quantity
        this.items.push({ ...newItem, quantite ,ispaquet});
      }
    },
    updateQuantity(itemToUpdate, newQuantite) {
      let existingItem;
    
      if (itemToUpdate.ispaquet) {
        // Find the corresponding package item by its id
        existingItem = this.items.find(item => item.id_paquet === itemToUpdate.id_paquet);
      } else {
        // Find the corresponding product item by its id
        existingItem = this.items.find(item => item.id_produit === itemToUpdate.id_produit);
      }
    
      if (existingItem) {
        // Update the quantity if the item exists
        existingItem.quantite = newQuantite;
      } else {
        console.error('Item not found in the cart.');
      }
    },
    
    
    // remove(itemToRemove) {
    //   if (itemToRemove.ispaquet) {
    //     // Si c'est un paquet, supprimer tous les articles du paquet
    //     this.items = this.items.filter(item =>( item.id_paquet ||  item.id_paquet) !== itemToRemove.id_paquet);
    //   } else {
    //     // Sinon, supprimer juste l'article spécifique
    //     this.items = this.items.filter(item => ( item.id_paquet ||  item.id_paquet)!== itemToRemove.id_produit);
    //   }
    // },
    remove(itemToRemove) {
      if (itemToRemove.ispaquet) {
        // Si c'est un paquet, supprimer l'élément correspondant au paquet
        this.items = this.items.filter(item => item.id_paquet !== itemToRemove.id_paquet);
      } else {
        // Sinon, supprimer l'élément correspondant au produit spécifique
        this.items = this.items.filter(item => item.id_produit !== itemToRemove.id_produit);
      }
    },
    
    toggleCart() {
      this.showCart = !this.showCart;
    },
    closeCart() {
      this.showCart = false;
    },
    openCart() {
      this.showCart = true;
    },    
    clearCart() {
      this.items = [];
    }
    
  },
});

// Hot Module Replacement (HMR)
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
