<template>
  <div class="min-w-[300px] min-h-[400px]">
    <div class="login-container">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" v-model="email" required />
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" v-model="password" required />
        </div>
        <button type="submit">Login</button>
      </form>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
  methods: {
    ...mapActions(['loginAsAdmin']),

    async login() {
      try {
        const response = await axios.post("login", {
          email: this.email,
          password: this.password,
        });
        if (response.data.user && response.data.user.statut === "admin") {
          this.loginAsAdmin({ 
            user: response.data.user, 
            token: response.data.token 
          });
          this.$router.push('/');
        } else {
          console.error("error lors de la connexion");
        }
      } catch (error) {
        this.$logErrorToBackend("Error",error.message);
        this.error = "Invalid credentials, please try again.";
      }
    },
  },
  computed: {
    ...mapGetters(['isAdmin', 'currentUser']),
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

p {
  color: red;
}
</style>
