<template>
  <div class="container">
    <!-- Formulaire pour ajouter une image -->
    <div class="card mb-4">
      <div class="card-header">
        <h3 class="text-center">Ajouter une image</h3>
      </div>
      <div class="card-body">
        <div v-if="success" class="alert alert-success" role="alert">
          <i class="fas fa-check-circle"></i> Image ajoutée avec succès !
        </div>
        <form @submit.prevent="addImages" enctype="multipart/form-data">
          <div class="mb-3">
            <label for="images" class="form-label">Images</label>
            <input type="file" class="form-control" id="images" @change="handleFileUpload" accept="image/*" multiple>
          </div>
          <div v-if="resizedImages.length" class="mb-3">
            <p>Images redimensionnées :</p>
            <ul>
              <li v-for="(image, index) in resizedImages" :key="index">
                {{ image.name }} - {{ formatFileSize(image.size) }}
              </li>
            </ul>
          </div>
          <button type="submit" class="btn btn-primary w-100">Ajouter les images</button>
        </form>
      </div>
    </div>

    <!-- Liste des images -->
    <div class="card">
      <div class="card-header">
        <h3 class="text-center">Liste des images</h3>
      </div>
      <div class="card-body">
        <div v-if="images.length === 0" class="text-center">Aucune image disponible</div>
        <div v-else>
          <div v-for="image in images" :key="image.id" class="d-flex align-items-center mb-3">
            <img :src="`${image.image_path}`" alt="Image Slide" class="img-thumbnail me-3" style="width: 100px;">
            <button @click="deleteImage(image.id)" class="btn btn-danger">Supprimer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import axiosInstance from '@/Axiosauth';

export default {
  data() {
    return {
      success: false,
      images: [],
      selectedFiles: [],
      resizedImages: []
    }
  },
  methods: {
    async addImages() {
      let formData = new FormData();

      this.resizedImages.forEach((img, index) => {
        formData.append(`images[${index}]`, img);
      });

      try {
        const response = await axiosInstance.post('image-slides', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.success) {
          this.success = true;
          this.fetchImages(); // Rafraîchir la liste des images après ajout
          setTimeout(() => {
            this.success = false;
          }, 3000);
        }
      } catch (error) {
        this.$logErrorToBackend("error: ", error.message);
        alert('Une erreur est survenue lors de l\'ajout des images.');
      }
    },
    async handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.resizedImages = [];

      for (const file of files) {
        const resizedImage = await this.resizeImage(file);
        this.resizedImages.push(resizedImage);
      }
    },
    resizeImage(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
            
            // Redimensionner si l'image est trop grande
            const MAX_WIDTH = 1024;
            const MAX_HEIGHT = 1024;
            
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            
            canvas.toBlob((blob) => {
              const resizedFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              });
              resolve(resizedFile);
            }, 'image/jpeg', 0.7); // Qualité de compression à 70%
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    formatFileSize(bytes) {
      if(bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    async fetchImages() {
      try {
        const response = await axios.get('image-slides')
        this.images = response.data
      } catch (error) {
        this.$logErrorToBackend("error: ", error.message);
      }
    },
    async deleteImage(id) {
      try {
        await axiosInstance.delete(`image-slides/${id}`)
        alert('Image supprimée avec succès')
        this.fetchImages() // Rafraîchir la liste des images après suppression
      } catch (error) {
        this.$logErrorToBackend("error: ", error.message);
        alert('Une erreur est survenue lors de la suppression de l\'image.')
      }
    }
  },
  mounted() {
    this.fetchImages() // Charger les images lors du montage du composant
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 2rem auto;
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  padding: 1.5rem;
}

.card-body {
  padding: 2rem;
}

.form-label {
  font-weight: 600;
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 0.75rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.alert {
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.img-thumbnail {
  max-width: 100px;
}
</style>