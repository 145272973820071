import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Importation du store Vuex
import './assets/tailwind.css';
import { createPinia } from 'pinia'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faShoppingCart ,faEllipsisVertical} from '@fortawesome/free-solid-svg-icons'; // Importer des icônes spécifiques
import VueLazyload from 'vue-lazyload'

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import axios from 'axios';
// Ajouter l'icône à la bibliothèque FontAwesome
library.add(faCheck);
library.add(faShoppingCart,faEllipsisVertical); // Ajouter des icônes à la bibliothèque


// Créer l'application Vue
const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(VueLazyload, {
  preLoad: 1.3,
  error: 'path/to/error/image.jpg',
  loading: 'path/to/loading/image.gif',
  attempt: 1
})
axios.defaults.baseURL='https://web-production-fe642.up.railway.app/api/';

// Définir la méthode globale $getProduitDetaille
app.config.globalProperties.$getProduitDetaille = async function(id) {
  try {
    const response = await axios.get(`produits/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des produits:', error);
    throw error;
  }
};
app.config.globalProperties.$logErrorToBackend = async (context, errorMessage) => {
    await axios.post('logs', {
      message: ` ${errorMessage}`,
      level: 'error'
    });
};

app.use(pinia)

// Utiliser les composants et plugins
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.use(store); // Intégrer le store Vuex

// Monter l'application
app.mount('#app');
