import axios from 'axios';
import store from './store'; // Assurez-vous que le chemin vers votre store est correct

const axiosInstance = axios.create({
  baseURL:"https://web-production-fe642.up.railway.app/api/",
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getters.token;
    if (token) { 
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;