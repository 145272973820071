<template>
  <div dir="rtl" class="container">
    <div class="card">
      <div class="custom-title">
        <h1 class="text-center">إرسال الطلب</h1>
      </div>
      <div dir="rtl" class="custom card-body">
        <div v-if="succes" class="alert alert-success" role="alert">
          <i class="fas fa-check-circle"></i> لقد تم إرسال طلبكم
        </div>
        <div v-if="erreur" class="alert alert-danger" role="alert">
          <i class="fas fa-check-circle"></i> حدث خطأ أثناء إرسال الطلب،المرجو إعادة المحاولة أو التواصل معنا
        </div>
        <form @submit.prevent="addPaque" enctype="multipart/form-data">
          <div class="mb-3">
            <label for="nom" class="form-label"> الإسم الكامل : </label>
            <input type="text" class="form-control border-dark" id="libelle" v-model="client.nom" required>
          </div>
          <div class="mb-3">
            <label for="tel" class="form-label"> رقم الهاتف : </label>
            <input type="text" class="form-control border-dark" v-model="client.tel" required>
          </div>
          <div class="mb-3">
            <label for="adresse" class="form-label"> العنوان : </label>
            <textarea class="form-control border-dark" v-model="client.adresse" rows="3" placeholder="العنوان "
              required></textarea>
          </div>
          <div class="mb-3">
            <label for="description" class="form-label"> المقاس ـ اللون : </label>
            <textarea class="form-control border-dark" v-model="message" rows="3" placeholder="" required></textarea>
          </div>
          <button @click="openModal"
            class="w-full mt-2 mb-4 bg-[#F4CE14] text-white font-bold py-2 px- rounded-lg hover:from-purple-700 hover:to-indigo-700 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 shadow-lg">
            <span class="flex items-center justify-center">
              <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122">
                </path>
              </svg>
              طريقة القياس
            </span>
          </button>
          <button type="submit" class="btn  bg-[#50B498] w-100" @click="addClient"> إرسال </button>
        </form>
        <div v-if="IsOpen" class="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
          aria-modal="true">
          <div class="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"
              @click="closeModal">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div
              class="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h1 class="text-lg font-medium leading-6 text-gray-1100" id="modal-title">
                      <p class="text-2xl font-semibold mb-6">كيفاش ناخد العبار ؟؟</p>
                    </h1>
                    <div class="mt-4 space-y-4">
                      <div v-for="(image, index) in ['9iyass.png', 'mada.png', 'sadr.png']" :key="index" class="mb-4">
                        <div class="mb-2 text-lg font-medium text-gray-800">{{ ['قياس الطول', 'قياس المادة',
                          'قياس الصدر'][index] }}</div>
                        <div class="relative w-full pt-[56.25%]"> <!-- 16:9 Aspect Ratio -->
                          <img :src="require(`@/assets/produits_images/${image}`)"
                            class="absolute top-0 left-0 w-full h-100 object-contain rounded-lg shadow-md">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                <button @click="closeModal" type="button"
                  class="custom inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                   إلغاء
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script setup>
import { useCartStore } from '@/useCartStore';
import { ref, reactive } from 'vue';
import axios from 'axios';

const cart = useCartStore();
const succes = ref(false);
const erreur=ref(false);
const message = ref("");
const client = reactive({
  nom: "",
  adresse: "",
  tel: "",
});
const IsOpen = ref(false);


const addClient = async () => {
  let formData = new FormData();
  formData.append('tel', client.tel);
  formData.append('adresse', client.adresse);
  formData.append('nom', client.nom);
  try {
    const response = await axios.post('clients', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.data.success) {
      createCommande(response.data.data);
    }
  } catch (error) {
   console .error('المرجو إدخال المعلومات كاملة')
  }
};
const openModal = () => {
  IsOpen.value = true;
};

const closeModal = () => {
  IsOpen.value = false;
};

const createCommande = async (id) => {
  const currentDate = new Date();
  let formData = new FormData();
  formData.append('id_client', id);
  formData.append('date_commande', currentDate.toISOString().split('T')[0]);
  formData.append('statut', "en_cours");
  formData.append('statut_cash', "en_cours");
  formData.append('total', cart.total);
  formData.append('message', message.value);


  cart.items.forEach((item, index) => {

    if (item.ispaquet) {
      formData.append(`lignes[${index}][id_produit]`, '');
      formData.append(`lignes[${index}][id_paquet]`, item.id_paquet || '');
    } else {
      formData.append(`lignes[${index}][id_produit]`, item.id_produit || '');
      formData.append(`lignes[${index}][id_paquet]`, '');
    }
    formData.append(`lignes[${index}][quantite]`, item.quantite);
    formData.append(`lignes[${index}][prix_unitaire]`, item.prix);
  });


  try {
    const response = await axios.post('commande', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.data.success === true) {
      succes.value = true;
      // Optionnel : vider le panier après une commande réussie
      cart.clearCart();
    }
  } catch (error) {
    erreur.value = true;
  }
};
</script>
<style scoped>
.container {
  max-width: 500px;
  margin: 2rem auto;
}

.custom-title {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 2rem);
  color: #608015;

}

.custom {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 1.4rem);
  color: #081f16;
  text-align: center;
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  padding: 1.5rem;
}

.card-body {
  padding: 2rem;
}

.form-label {
  font-weight: 600;
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 0.75rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.alert {
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}
</style>