<template>
  <div v-if="isLoading" class="flex justify-center items-center h-64">
    <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
  <div v-else-if="error" class="text-red-500 text-center py-8">{{ error }}</div>
  <div v-else class="product-detail container px-4 py-8">
    <router-link v-if="isAdmin" :to="{ name: 'UpdateProduit', params: { id: $route.params.id } }"
        class="inline-flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-300">
        تغيير
    </router-link>
    <div class="flex flex-col gap-10 lg:flex-row items-start">
      <!-- Galerie d'images -->
      <div class="image-gallery w-full lg:w-1/2 order-1 lg:order-2 sm:mb-8">
        <div class="main-image mb-4">
          <img :src="mainImage.image_path"
            class="w-full h-auto object-cover aspect-[4/3] rounded-lg shadow-lg">
        </div>
        <div v-if="hasImages" class="grid grid-cols-5 sm:mb-8 gap-2">
          <div v-for="(image, index) in product.images" :key="index" @click="setMainImage(index)"
            class="cursor-pointer transition-all duration-300 hover:opacity-75">
            <img :src="image.image_path"
              :alt="`${product.libelle} - Image ${index + 1}`"
              class="w-full h-auto object-cover aspect-square rounded-md shadow">
          </div>
        </div>
      </div>

      <!-- Informations sur le produit -->
      <div dir="rtl" class="product-info lg:ml-16 sm:mt-12 bg-black w-full lg:w-1/2 order-1 lg:order-1 bg-white rounded-xl shadow-lg p-6">
        <h1 class="custom-title text-2xl">{{ product.libelle }}</h1>
        <p class="custom-description text-gray-600 mb-6 ">{{ product.description }}</p>
        <p class="custom text-2xl font-semibold mb-6"> الثمن : {{ product.prix }} درهم </p>

        <!-- Bouton pour ouvrir le modal -->
        <div class="mt-6 space-y-4">
          <button @click="addToCart(product, 1)"
            class="w-full bg-[#468585] text-white font-semibold py-2 px-4 rounded-lg hover:bg-lime-600 transition duration-300 ease-in-out flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg> إضافة إلى السلة</button>
          <button @click="byNow(product)"
            class="w-full bg-green-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out">أشتري
            الأن</button>
        </div>
        <button @click="openModal"
          class="w-full mt-6 bg-[#F4CE14] text-white font-bold py-2 px- rounded-lg hover:from-purple-700 hover:to-indigo-700 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 shadow-lg">
          <span class="flex items-center justify-center">
            <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122">
              </path>
            </svg>
            طريقة القياس
          </span>
        </button>
      </div>
    </div>

    <!-- Modern Modal -->
    <div v-if="isOpen" class="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
      aria-modal="true">
      <div class="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true" @click="closeModal">
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          class="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h1 class="text-lg font-medium leading-6 text-gray-1100" id="modal-title">
                  <p class="text-2xl font-semibold mb-6">كيفاش ناخد العبار ؟؟</p>
                </h1>
                <div class="mt-4 space-y-4">
                  <div v-for="(image, index) in ['9iyass.png', 'mada.png', 'sadr.png']" :key="index" class="mb-4">
                    <div class="mb-2 text-lg font-medium text-gray-800">{{ ['قياس الطول', 'قياس المادة',
                      'قياس الصدر'][index] }}</div>
                    <div class="relative w-full pt-[56.25%]"> <!-- 16:9 Aspect Ratio -->
                      <img :src="require(`@/assets/produits_images/${image}`)"
                        class="absolute top-0 left-0 w-full h-100 object-contain rounded-lg shadow-md">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="closeModal" type="button"
              class="custom inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              إلغاء
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h2 class="custom-title text-center font-bold mt-10 mb-4">منتوجات قد تعجبك أيضا </h2>
      <Swiper :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" :slidesPerView="2" :coverflowEffect="{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }" :pagination="{ clickable: true }" :spaceBetween="20" :navigation="false" :modules="swiperModules" :autoplay="{
    delay: 1500,
    disableOnInteraction: false,
  }" class="mySwiper">
        <!-- Vos images ici -->
        <SwiperSlide v-for="product in produits" :key="product.id_produit">
          <router-link :to="{ name: 'ProductDetaille', params: { id: product.id_produit } }" class="block">
            <div class="product-container">
              <div class="product-image transform transition duration-500 hover:scale-105">
                <img :src="product.images[0].image_path"
                  class="rounded-lg w-full h-64 object-cover shadow-lg" />
              </div>
              <div dir="rtl" class="product-info mt-4 text-center">
                <h3 class="custom-text font-semibold">{{ product.libelle }}</h3>
                <p class="custom-text text-lg font-bold mt-2 mb-8">{{ product.prix }} درهم</p>
              </div>
            </div>
          </router-link>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { mapGetters } from 'vuex';
import { useCartStore } from '@/useCartStore';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      product: null,
      isLoading: true,
      error: null,
      mainImage: null,
      isOpen: false,
      produits: [],
      cart: useCartStore(),
      VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL,
      swiperModules: [Pagination, Navigation, Autoplay],
    };
  },
  computed: {
    hasImages() {
      return this.product && this.product.images && this.product.images.length > 0;
    },
    ...mapGetters(['isAdmin']),
  },
  mounted() {
    this.getProduitDetaille();
  },
  watch: {
    '$route'(to, from) {
      this.getProduitDetaille();
    }
  },
  methods: {
    async getProduitDetaille() {
      try {
        const response = await axios.get(`produits/${this.$route.params.id}`);
        if (response.data) {
          this.product = response.data.data;
          this.mainImage = this.hasImages ? this.product.images[0] : null;
        } else {
          this.product = null;
          this.error = "Aucun produit trouvé.";
        }
      } catch (error) {
        this.error = "Une erreur est survenue lors du chargement du produit.";
      } finally {
        this.isLoading = false;
        if (this.product) {
          this.getProduitsInSousCategory(this.product.id_sous_categorie);
        }
      }
    },
    async getProduitsInSousCategory(id) {
      const url = `show/produit/souscategory/${id}`;
      try {
        const response = await axios.get(url);
        if (response.data) {
          this.produits = response.data;
          this.currentPage = response.data.current_page;
          this.totalPages = response.data.last_page;
        } else {
          this.produits = [];
        }
      } catch (error) {
        this.$logErrorToBackend("Une erreur est survenue lors du chargement des produits.", error.message);
        this.error = "حدث حطأ أثناء تحميل الصفحة المرجو إعاد المحاولة";
      } finally {
        this.isLoading = false;
      }
    },
    openModal() {
      this.isOpen = true;
    },
    setMainImage(index) {
      this.mainImage = this.product.images[index];
    },
    closeModal() {
      this.isOpen = false;
    },
    addToCart(produit) {
      this.cart.add(produit, 1, false);
    },
    byNow(produit) {
      this.cart.add(produit, 1,false);
      this.cart.openCart();
    }
  }
};
</script>
<style scoped>
.custom {
  font-family: 'Arabswell', serif;
  font-size: larger;
  color: #17c55a;
  margin-bottom: 1rem;
}

.custom-title {
  font-family: 'Arabswell', serif;
  font-size: xx-large;
  color: #111813;
  margin-bottom: 1rem;
}

.custom-description {
  font-family: 'Arabswell', serif;
  color: #468585;
  margin-bottom: 1rem;
}

.custom-titled {
  font-family: 'Arabswell', serif;
  color: #000000;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.swiper-container {
  height: 400px;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

@media (max-width: 640px) {
  .main-image img {
    height: 250px;
  }
}

.main-image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.product-info {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-info:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.custom-text {
  font-family: 'Arabswell', serif;
  color: #0e0e08;
  font-size: 1.2rem;
}
</style>