<template>
  <div class="min-h-[400px] bg-white">
    <div v-if="isLoading" class="flex justify-center items-center h-64">
      <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
    <!-- -if=" !error -->
    <div v-else id="custom-controls-gallery" class="relative w-full" data-carousel="slide">
      <Swiper :modules="modules" :slides-per-view="1" :space-between="5" :centered-slides="true"
        :pagination="{ clickable: true }" :navigation="false" :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }" class="mySwiper h-screen max-h-[400px]">
        <SwiperSlide v-for="(image, index) in images" :key="index">
          <div class="transform transition duration-500 hover:scale-105 h-full">
            <img :src="`${image.image_path}`" loading="lazy"
              class="rounded-lg w-full h-full object-cover shadow-lg" />
          </div>
        </SwiperSlide>
      </Swiper>
      <div>
        <h2 class="custom-title font-bold mb-4">المنتوجات الأكثر طلبا</h2>
        <Swiper v-if="produits.length > 0" :modules="modules" :slides-per-view="2.5" :space-between="5"
          :centered-slides="true" :pagination="{ clickable: true }" :navigation="false" :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
          }" class="mySwiper">

          <SwiperSlide v-for="product in produits" :key="product.id_produit">
            <router-link :to="{ name: 'ProductDetaille', params: { id: product.id_produit } }" class="block">
              <div class="product-container">
                <div class="product-image transform transition duration-500 hover:scale-105">
                  <img :src="product?.images[0]?.image_path" loading="lazy"
                    class="rounded-lg w-full h-64 object-contain shadow-lg" />

                </div>
                <div dir="rtl" class="product-info mt-4 text-center">
                  <h3 class="custom-text font-semibold">{{ product.libelle }}</h3>
                  <p class="custom-text text-lg font-bold mt-2">{{ product.prix }} درهم</p>
                </div>
              </div>
            </router-link>
          </SwiperSlide>
        </Swiper>
        <h2 class="custom-title font-bold mb-4">عروضنا</h2>
        <Swiper v-if="paquets.length > 0" :modules="modules" :slides-per-view="2.5" :space-between="5"
          :centered-slides="true" :pagination="{ clickable: true }" :navigation="false" :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
          }" class="mySwiper">
          <SwiperSlide v-for="paquet in paquets" :key="paquet.id_paquet">
            <router-link :to="{ name: 'Paquedetaille', params: { id: paquet.id_paquet } }" class="block">
              <div class="product-container">
                <div class="product-image transform transition duration-500 hover:scale-105">
                  <img :src="`${paquet.images[0].image_path}`" loading="lazy"
                    class="rounded-lg w-full h-64 object-cover shadow-lg" />
                </div>
                <div dir="rtl" class="product-info mt-4 text-center">
                  <h3 class="custom-text font-semibold">{{ paquet.libelle }}</h3>
                  <p class="custom-text text-lg font-bold mt-2">{{ paquet.prix }} درهم</p>
                </div>
              </div>
            </router-link>
          </SwiperSlide>
        </Swiper>
        <h2 v-if="categories.length > 0" class="custom-title font-bold mb-4">التصنيفات</h2>
        <div v-for="categorie in categories" :key="categorie.id_categorie" class="category-slider mb-10">
          <h2 class="custom font-bold mr-16 text-end">{{ categorie.libelle }}</h2>
          <Swiper :modules="modules" :slides-per-view="2.5" :space-between="5" :centered-slides="true"
            :pagination="{ clickable: true }" :navigation="false" :autoplay="{
              delay: 3000,
              disableOnInteraction: false,
            }" class="mySwiper">
            <SwiperSlide v-for="produit in getCategoryProducts(categorie.id_categorie)" :key="produit.id_produit">
              <router-link :to="{ name: 'ProductDetaille', params: { id: produit.id_produit  }}" class="block">
                <div class="product-container">
                  <div class="product-image transform transition duration-500 hover:scale-105">
                    <img :src="produit?.images[0]?.image_path" loading="lazy"
                      class="rounded-lg w-full h-64 object-contain shadow-lg" />

                  </div>
                  <div dir="rtl" class="product-info mt-4 text-center">
                    <h3 class="custom-text font-semibold">{{ produit.libelle }}</h3>
                    <p class="custom-text text-lg font-bold mt-2">{{ produit.prix }} درهم</p>
                  </div>
                </div>
              </router-link>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { mapGetters } from 'vuex';
import axios from 'axios';
import 'swiper/css/autoplay';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isReady: false,
      isLoading: true,
      error: null,
      categories: [],
      paquets: [],
      allProducts: {},
      modules: [Navigation, Pagination, Autoplay],
      images: [],
      produits: [],
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapGetters(['currentUser']),
  },
  async mounted() {
    try {
      await Promise.all([
        this.getCategoriesName(),
        this.getProductsLatest(),
        this.getPaquesLatest(),
        this.fetchImages()
      ]);
      // this.isReady = true;

      // Charger tous les produits après avoir obtenu les catégories
      await this.getAllProducts();
    } catch (err) {
      this.error = "حدث حطأ أثناء تحميل الصفحة المرجو إعاد المحاولة";
      this.$logErrorToBackend("Une erreur est survenue lors du chargement des produits.", err.message);

    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getAllProducts() {
      for (const category of this.categories) {
        await this.getProductInCategory(category.id_categorie);
      }
    },
    async getProductInCategory(id) {
      try {
        const response = await axios.get(`show/produit/categorie/${id}`);

        this.allProducts[id] = response.data || [];
      } catch (err) {
        console.error("Error fetching products:", err);
        throw err;
      }
    },
    async fetchImages() {
      try {
        const response = await axios.get('image-slides')
        this.images = response.data
      } catch (error) {
        console.error('Error fetching images:', error.response ? error.response.data : error.message)
      }
    },
    async getCategoriesName() {
      try {
        const response = await axios.get('showcategoriesnames');
        this.categories = response.data?.data || [];
      } catch (err) {
        console.error("Error fetching categories:", err);
        throw err;
      }
    },
    getCategoryProducts(categoryId) {
      return this.allProducts[categoryId] || [];
    },
    async getProductsLatest() {
      try {
        const response = await axios.get('produits');
        this.produits = response.data.data || [];
      } catch (err) {
        console.error("Error fetching produits:", err);
        throw err;
      }
    },
    async getPaquesLatest() {
      try {
        const response = await axios.get('paquets');
        this.paquets = response.data.data.data;
      } catch (err) {
        console.error("Error fetching paquets:", err);
        throw err;
      }
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    }
  },
};
</script>

<style scoped>
.category-slider {
  margin-bottom: 40px;
}

.mySwiper {
  padding: 30px 0;
}

.product-container {
  padding: 10px;
}

.product-image img {
  transition: transform 0.3s ease;
  object-fit: cover;

}

.product-image:hover img {
  transform: scale(1.05);
}

.custom-text {
  font-family: 'Arabswell', serif;
  color: #0e0e08;
  font-size: 1.2rem;
}

.custom {
  font-family: 'Arabswell', serif;
  color: #9CDBA6;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.custom-title {
  font-family: 'Arabswell', serif;
  color: #468585;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}

:global(.swiper-button-next),
:global(.swiper-button-prev) {
  color: #0e0e08;
}

:global(.swiper-pagination-bullet) {
  background: #0e0e08;
  opacity: 0.6;
}

:global(.swiper-pagination-bullet-active) {
  opacity: 1;
}
</style>