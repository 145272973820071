import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import AddCategory from '../views/Admin/Category/AddCategory.vue';
import CategoryShow from '../views/Admin/Category/CategoryShow.vue';
import ShowSousCategory from '../views/Admin/SousCategory/ShowSousCategory.vue';
import AddSousCategory from '../views/Admin/SousCategory/AddSousCategory.vue';
import UpdateCategory from '../views/Admin/Category/UpdateCategory.vue';
import AddPaque from '../views/Admin/Paquets/AddPaque.vue';
import ShowPaque from '../views/Admin/Paquets/ShowPaque.vue';
import AddProduit from '../views/Admin/Produit/AddProduit.vue';
import ProduitIn from '../views/Admin/SousCategory/ProduitIn.vue';
import AddProduitIn from '../views/Admin/SousCategory/AddProduitIn.vue';
import AddProduitInPaque from '../views/Admin/Paquets/AddProduitInPaque.vue';
import ShowCommandes from '../views/Admin/Commandes/ShowCommandes.vue';
import ProductDetaille from '../views/User/ProductDetaille.vue';
import Paque from '@/views/User/Paque.vue';
import Confirm from '@/views/User/Confirm.vue';
import NavNew from '@/views/NavNew.vue';
import Login from '@/views/Admin/auth/Login.vue';
import About from '@/components/Category/About.vue';
import ImagesSLide from '@/views/Admin/ImagesSLide.vue';
import Register from '@/views/Admin/auth/Register.vue';
import UpdateSousCategorie from '@/views/Admin/SousCategory/UpdateSousCategorie.vue';
import UpdateProduit from '@/views/Admin/Produit/UpdateProduit.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/category/add',
    name:'AddCategory',
    component: AddCategory,
    meta: { requiresAuth: true }
  },
  {
    path:'/paque/add',
    name:'AddPaque',
    component: AddPaque,
    meta: { requiresAuth: true }
  },
  {
    path:'/produit/add',
    name:'AddProduit',
    component: AddProduit,
    meta: { requiresAuth: true }
  },
  {
    path:'/add/produit/souscategorie/:id_sous_categorie/:id_categorie',
    name:'AddProduitIn',
    component: AddProduitIn,
    meta: { requiresAuth: true }
  },
  {
    path:'/category',
    name:'Category',
    component: CategoryShow,
  },
  {
    path:'/paque',
    name:'ShowPaque',
    component: ShowPaque,
  },
  {
    path:'/category/souscategory/show/:id',
    name:'ShowSousCategory',
    component: ShowSousCategory,
  },
  {
    path:'/AddSousCategorie/:id',
    name:'AddSousCategorie',
    component: AddSousCategory,
    meta: { requiresAuth: true }
  },
  {
    path:'/UpdateCategory/:id',
    name:'UpdateCategory',
    component: UpdateCategory,
    meta: { requiresAuth: true }
  },
  {
    path:'/UpdateSousCategory/:id',
    name:'UpdateSousCategory',
    component: UpdateSousCategorie,
    meta: { requiresAuth: true }
  },
  {
    path:'/UpdateProduit/:id',
    name:'UpdateProduit',
    component: UpdateProduit,
    meta: { requiresAuth: true }
  },
  {
    path:'/UpdatePaquet/:id',
    name:'UpdatePaquett',
    component: UpdateProduit,
    meta: { requiresAuth: true }
  },
  {
    path: '/Show/produit/souscategorie/:id_sous_categorie/:idCategory',
    name: 'ShowProduitIn',
    component: ProduitIn,
  },
  {
    path: '/Add/produit/paque/:id',
    name: 'AddProduitToPaquet',
    component: AddProduitInPaque,
    meta: { requiresAuth: true }
  },
  {
    path: '/commandes',
    name: 'ShowCommandes',
    component: ShowCommandes,
    meta: { requiresAuth: true }
  },
   //
   {
    path: '/product/:id',
    name: 'ProductDetaille',
    component: ProductDetaille,
  },
  {
    path: '/user/paque/:id',
    name: 'Paquedetaille',
    component: Paque,
  },

  {
    path: '/CreateCommande',
    name: 'Confirm',
    component: Confirm,

  },
  {
    path: '/Nav',
    name: 'NavNew',
    component: NavNew,

  },
  {
    path: '/amohamed',
    name: 'Login',
    component: Login,

  },
  // {
  //   path: '/khayhmed',
  //   name: 'Register',
  //   component: Register,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/about',
    name: 'About',
    component: About,

  },
  {
    path: '/image',
    name: 'image',
    component: ImagesSLide,
    meta: { requiresAuth: true }

  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
