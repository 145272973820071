<template>
  <div class="category-box">
    <div v-if="category.categorie_images" class="swiper-container">
      <Swiper :effect="'cube'" :grabCursor="true" :cubeEffect="{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }" :pagination="{ clickable: true }" :navigation="true" :modules="swiperModules" :autoplay="{
        delay: 1500,
        disableOnInteraction: false,
      }" class="mySwiper">
        <SwiperSlide v-for="image in category.categorie_images" :key="image.id">
          <img :src="`${image.image_path}`" class="w-full h-full object-cover">
        </SwiperSlide>
      </Swiper>
    </div>
    <div dir="rtl" class="category-info">
      <h3 class="category-title">{{ category.libelle }}</h3>
      <p class="category-description">{{ category.description || "" }}</p>
    </div>
    <div class="flex items-center justify-center space-x-4" role="group" aria-label="Category actions">
      <!-- Bouton pour modifier la catégorie (visible uniquement pour les administrateurs) -->
      <router-link v-if="isAdmin" :to="{ name: 'UpdateCategory', params: { id: category.id_categorie } }"
        class="inline-flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-300">
        تغيير
      </router-link>

      <!-- Bouton pour supprimer la catégorie (visible uniquement pour les administrateurs) -->
      <button v-if="isAdmin" type="button" @click="deleteCategory"
        class="inline-flex items-center justify-center bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-300">
        حذف
      </button>

      <!-- Bouton pour afficher les sous-catégories (visible pour tous les utilisateurs) -->
      <router-link :to="{ name: 'ShowSousCategory', params: { id: category.id_categorie } }"
        class="inline-flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-300">
        <span class="category-title">الأشكال</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/Axiosauth';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCube, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { mapGetters } from 'vuex';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

export default {
  name: 'CategoryBox',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      swiperModules: [EffectCube, Pagination, Navigation, Autoplay],
      dropdownOpen: false,
    };
  },
  methods: {
    async deleteCategory() {
      if (confirm("Êtes-vous sûr de vouloir supprimer cette catégorie?")) {
        try {
          await axiosInstance.delete(`categories/${this.category.id_categorie}`);
          this.$emit('categoryDeleted', this.category.id_categorie);
          alert("Catégorie supprimée avec succès !");
        } catch (error) {
          this.$logErrorToBackend("Error lors de la suppresion de la sous categorie", error.message); // Appel de la méthode globale
          alert("Erreur lors de la suppression de la catégorie.");
        }
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    }
  }, computed: {
    ...mapGetters(['isAdmin']),
  },
};
</script>

<style scoped>
.category-box {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.category-box:hover {
  transform: translateY(-5px);
}

.swiper-container {
  height: 250px;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-info {
  padding: 1.5rem;
}

.category-title {
  font-family: 'Arabswell', serif;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.category-description {
  font-family: 'Arabswell', serif;
  color: #666;

  margin-bottom: 1rem;
}

.category-actions {
  display: inline-block
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #4CAF50;
  color: white;
}

.btn-primary:hover {
  background-color: #45a049;
}

.btn-secondary {
  background-color: #2196F3;
  color: white;
}

.btn-secondary:hover {
  background-color: #1e87db;
}

.btn-danger {
  background-color: #f44336;
  color: white;
}

.btn-danger:hover {
  background-color: #da190b;
}

.admin-actions {
  display: flex;
  gap: 0.5rem;
}
</style>