<template>
  <div class="min-w-[300px] min-h-[600px]">
    <div class="container">
      <div dir="rtl" v-if="isAdmin" class="mt-4">
        <router-link :to="{ name: 'AddSousCategorie', params: { id: $route.params.id } }">
          <button type="button"
            class="flex items-center gap-2 bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clip-rule="evenodd" />
            </svg>
            إضافة
          </button>
        </router-link>
      </div>
      <div v-if="isLoading" class="flex justify-center items-center h-64">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
      <div v-else-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div v-else>
        <div v-if="souscategories.length > 0" class="row">
          <div v-for="souscategory in souscategories" :key="souscategory.id_sous_categorie"
            class="col-md-4 col-sm-6 mb-4 mt-10">
            <div class="category-box">
              <div class="swiper-container mb-10">
                <Swiper :effect="'cube'" :grabCursor="true" :cubeEffect="{
                  shadow: true,
                  slideShadows: true,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }" :pagination="{ clickable: true }" :navigation="false" :modules="swiperModules" :autoplay="{
                  delay: 1500,
                  disableOnInteraction: false,
                }" class="mySwiper">
                  <SwiperSlide v-for="image in souscategory.images" :key="image.id">
                    <img :src="`${image.image_path}`" class="w-full h-full object-cover">
                  </SwiperSlide>
                </Swiper>
              </div>
              <div dir="rtl" class="mb-6">
                <p class="custom">{{ souscategory.libelle }}</p>
                <p class="custom">{{ souscategory.description }}</p>
              </div>
              <div class="flex justify-center sm:mb-6 w-full">
                <router-link class="w-full ml-8 mr-8"
                  :to="{ name: 'ShowProduitIn', params: { id_sous_categorie: souscategory.id_sous_categorie, idCategory: $route.params.id } }">
                  <button type="button"
                    class="custom bg-[#50B498] lg:px-1 text-white sm:w-full py-2 px-4 hover:bg-[#468585] rounded">المنتوجات</button>
                </router-link>
              </div>
              <div class="flex justify-center" v-if="isAdmin">
                <button type="button" class="btn btn-danger"
                  @click="deleteSousCategory(souscategory.id_sous_categorie)">Supprimer</button>
                <router-link  :to="{ name: 'UpdateSousCategory', params: { id:souscategory.id_sous_categorie } }"
                  class="inline-flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-300">
                  تغيير
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-warning">
          <p>Pas de sous-catégories disponibles pour cette catégorie.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCube, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { mapGetters } from 'vuex';
import axios from 'axios';
import axiosInstance from '@/Axiosauth';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

export default {
  name: 'CategoryShow',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      souscategories: [],
      isLoading: true,
      error: null,
      VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL,
      swiperModules: [EffectCube, Pagination, Navigation, Autoplay],
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  watch: {
    '$route'(to, from) {
      this.getSousCategories();
    }
  },
  mounted() {
    this.getSousCategories();
  },
  methods: {
    async getSousCategories() {
      const url = `show/category/souscategory/${this.$route.params.id}`;
      try {
        const response = await axios.get(url);
        if (response && response.data) {
          this.souscategories = response.data;
        } else {
          this.souscategories = [];
        }
      } catch (error) {
        this.$logErrorToBackend("Error fetching sous categories", error.message);
        this.error = "حدث حطأ أثناء تحميل الصفحة المرجو إعاد المحاولة";
      } finally {
        this.isLoading = false;
      }
    },
    async deleteSousCategory(id) {
      if (confirm("Êtes-vous sûr de vouloir supprimer cette sous catégorie?")) {
        try {
          await axiosInstance.delete(`sous-categories/${id}`);
          this.$emit('categoryDeleted', id);
          alert("Sous Catégorie supprimée avec succès !");
          this.getSousCategories();
        } catch (error) {
          this.$logErrorToBackend("Erreur lors de la suppression de la sous catégorie.", error.message);
          alert("Erreur lors de la suppression de la sous catégorie.");
        }
      }
    }
  }
};
</script>

<style scoped>
.category-box {
  transition: transform 0.3s ease-in-out;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 auto;
}

.category-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.swiper-container {
  height: 400px;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom {
  font-family: 'Arabswell', serif;
  color: #666;
  margin-bottom: 0.1rem;
}

.bs-component {
  display: flex;
  align-items: center;
}
</style>