<template>
  <NavNew></NavNew>  
  <router-view></router-view>
  
  <FooterComponent class="">
    <p>© 2024 Votre Nom. Tous droits réservés.</p>
  </FooterComponent>
</template>

<script>
import FooterComponent from './components/Category/FooterComponent.vue';
import NavNew from './views/NavNew.vue';

export default {
  components: {
    FooterComponent,
    NavNew,
  },
  created() {
    // Appel de l'action pour vérifier l'authentification lors du démarrage de l'application
    this.$store.dispatch('checkAuth');
  },
};
</script>

<style>
@import './assets/tailwind.css'; /* Importation de Tailwind CSS */

@font-face {
    font-family: 'Arabswell';
    src: url('@/assets/fonts/arabswell_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Autres styles */
</style>
