<template>
  <div class="min-w-[300px] min-h-[400px]">

    <div v-if="isAdmin" class="container">
      <div class="card mb-4">
        <div class="card-header">
          <h1 class="text-center">Ajouter un produit dans ce paquet</h1>
        </div>
        <div class="card-body">
          <div v-if="success" class="alert alert-success" role="alert">
            <i class="fas fa-check-circle"></i> Produit ajouté avec succès !
          </div>
          <div v-if="categories.length > 0">
            <form @submit.prevent="addProduitTopaquet" enctype="multipart/form-data">
              <div class="mb-3">
                <label for="categorie" class="form-label">Catégorie</label>
                <select class="form-control" id="categorie" v-model="selectedCategorie" required>
                  <option value="" disabled selected>Sélectionnez une catégorie</option>
                  <option v-for="categorie in categories" :key="categorie.id_categorie" :value="categorie.id_categorie">
                    {{ categorie.libelle }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="sous-categorie" class="form-label">Sous-catégorie</label>
                <select class="form-control" id="sous-categorie" v-model="selectedSousCategorie" required>
                  <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                  <option v-for="sousCategorie in sousCategories" :key="sousCategorie.id_sous_categorie"
                    :value="sousCategorie.id_sous_categorie">
                    {{ sousCategorie.libelle }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="quantite" class="form-label">Quantité</label>
                <input type="number" class="form-control" id="quantite" v-model="produit.quantite"
                  placeholder="Entrez la quantité" required>
              </div>
              <button type="submit" class="btn btn-primary w-100">Ajouter le produit</button>
            </form>
          </div>
        </div>
      </div>

      <div v-if="produits.length > 0">
        <h2 class="mb-4">Produits Disponibles:</h2>
        <div class="row">
          <div v-for="produit in produits" :key="produit.id_produit" class="col-md-4 mb-4">
            <div class="card h-100">
              <img class="card-img-top" :src="`${produit.images[0].image_path}`" :alt="produit.libelle">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title">{{ produit.libelle }}</h5>
                <p class="card-text"><strong>{{ produit.prix }} DH</strong></p>
                <button type="button" class="btn btn-info mt-auto" @click="selectProduit(produit.id_produit)">
                  {{ selectedProduit === produit.id_produit ? 'Sélectionné' : 'Sélectionner' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axiosInstance from '@/Axiosauth';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      success: false,
      categories: [],
      sousCategories: [],
      produits: [],
      selectedCategorie: null,
      selectedSousCategorie: null,
      selectedProduit: null,
      VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL,
      produit: {
        quantite: 0,
        produit_id: null,
      }
    };
  },
  mounted() {
    this.getCategories();
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  watch: {
    // Watch for changes in `selectedCategorie`
    selectedCategorie(newVal) {
      if (newVal) {
        this.getSousCategories(newVal);  // Call getSousCategories with the new value
      }
    },
    selectedSousCategorie(newVal) {
      if (newVal) {
        this.getProduitInSouscategorie(newVal);  // Call getSousCategories with the new value
      }
    }
  },
  methods: {
    async getCategories() {
      try {
        const response = await axiosInstance.get('categories');
        this.categories = response.data.data.data;
      } catch (error) {
        this.$logErrorToBackend("Error fetching categgories ", error.message);
      }
    },
    async getSousCategories(categorieId) {
      try {
        const response = await axiosInstance.get(`show/category/souscategory/${categorieId}`);
        this.sousCategories = response.data;
      } catch (error) {
        this.$logErrorToBackend("Error fetching sous categories:", error.message);
      }
    },
    async getProduitInSouscategorie(souscategorieId) {
      try {
        const response = await axiosInstance.get(`show/produit/souscategory/${souscategorieId}`);
        this.produits = response.data;
      } catch (error) {
        this.$logErrorToBackend("Error fetching produits categories:", error.message);
      }
    },

    selectProduit(id_produit) {
      this.selectedProduit = id_produit;
      this.produit.produit_id = id_produit;

    },

    async addProduitTopaquet() {
      let formData = new FormData();
      formData.append('quantite', this.produit.quantite);
      formData.append('id_produit', this.produit.produit_id);
      formData.append('id_paquet', this.$route.params.id);

      try {
        const response = await axiosInstance.post('contenus-paquet', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.success) {
          this.success = true;
          alert(response.data.message);
          this.$router.push({ name: 'Paquedetaille', params: { id: this.$route.params.id } });
        }
      } catch (error) {
        this.$logErrorToBackend("Error lors de l\'ajout de produit ", error.message);
        alert('Une erreur est survenue lors de l\'ajout du produit.');
      }
    }
  }
};

</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 2rem auto;
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  padding: 1.5rem;
}

.card-body {
  padding: 1.5rem;
}

.form-label {
  font-weight: 600;
}

.form-control {
  border-radius: 4px;
}

.btn-primary,
.btn-info {
  border: none;
  padding: 0.75rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-info {
  background-color: #17a2b8;
  color: white;
}

.btn-info:hover {
  background-color: #138496;
}

.alert {
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}
</style>
