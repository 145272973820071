<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3 class="text-center">Modifier le produit</h3>
      </div>
      <div class="card-body">
        <!-- Message de succès -->
        <div v-if="success" class="alert alert-success" role="alert">
          <i class="fas fa-check-circle"></i> Produit mise à jour avec succès !
        </div>

        <!-- Formulaire -->
        <form v-if="produit" @submit.prevent="updateProduit" enctype="multipart/form-data">
          <!-- Libellé -->
          <div class="mb-3">
            <label for="libelle" class="form-label">Libellé</label>
            <input type="text" class="form-control" id="libelle" v-model="produit.libelle" required>
          </div>

          <!-- Description -->
          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <textarea class="form-control" id="description" v-model="produit.description" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label for="prix" class="form-label">Prix</label>
            <input type="text" class="form-control" id="prix" v-model="produit.prix" required>
          </div>

          <!-- Images existantes -->
          <div class="mb-3">
            <label class="form-label">Images actuelles</label>
            <div v-if="produit.images && produit.images.length" class="row">
              <div v-for="(image, index) in produit.images" :key="image.id || index" class="col-md-4 mb-2">
                <div class="card">
                  <img :src="image.image_path" class="card-img-top" alt="Image de catégorie">
                  <div class="card-body">
                    <button @click.prevent="removeImage(index)" class="btn btn-danger btn-sm">Supprimer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Ajouter de nouvelles images -->
          <div class="mb-3">
            <label for="newImages" class="form-label">Ajouter de nouvelles images</label>
            <input type="file" class="form-control" id="newImages" @change="handleFileUpload" accept="image/*" multiple>
            <!-- Afficher une erreur si une image dépasse 2MB -->
            <div v-if="error" class="alert alert-danger mt-2">{{ error }}</div>
          </div>

          <button type="submit" class="btn btn-primary w-100">Mettre à jour le produit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/Axiosauth';

export default {
  data() {
    return {
      success: false,
      produit: null,
      error: null,
      deletedImages: [] // Pour garder trace des images supprimées
    };
  },
  mounted() {
    this.getProduitDetails();
  },
  methods: {
    async getProduitDetails() {
      const url = `produits/${this.$route.params.id}`;
      try {
        const response = await axiosInstance.get(url);
        this.produit = response.data.data;
      } catch (error) {
        console.error("Erreur lors de la récupération des détails de la catégorie :", error.message);
      }
    },

    // Suppression d'une image dans le tableau
    // Suppression d'une image dans le tableau
    removeImage(index) {
      const image = this.produit.images[index];
      if (image && image.id) {
        this.deletedImages.push(image.id); // Marquez l'image pour suppression
      }
      this.produit.images.splice(index, 1); // Retirez visuellement
    },


    // Redimensionner une image si elle dépasse 2MB
    resizeImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 1024;
            const scale = maxWidth / img.width;
            canvas.width = maxWidth;
            canvas.height = img.height * scale;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
              resolve(blob);
            }, file.type);
          };
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },

    // Gérer l'ajout de nouvelles images
    async handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.error = null;

      const promises = files.map(async (file) => {
        if (file.size > 2 * 1024 * 1024) {
          const resizedImage = await this.resizeImage(file);
          this.produit.images.push(new File([resizedImage], file.name, { type: file.type }));
        } else {
          this.produit.images.push(file);
        }
      });

      await Promise.all(promises);
    },


    // Soumettre la catégorie mise à jour
    async updateProduit() {
      const produitId = this.$route.params.id;
      let formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('libelle', this.produit.libelle);
      formData.append('description', this.produit.description);
      formData.append('prix', this.produit.prix);
      formData.append('id_categorie', this.produit.id_categorie);
      formData.append('id_sous_categorie', this.produit.id_sous_categorie)

      // Ajouter les nouvelles images
      this.produit.images.forEach(image => {
        if (image instanceof File) {
          formData.append('images[]', image); // Fichiers nouvellement ajoutés
        }
      });

      // Ajouter les images supprimées
      if (this.deletedImages.length > 0) {
        formData.append('deleted_images', JSON.stringify(this.deletedImages));
      }

      try {
        const response = await axiosInstance.post(`produits/${produitId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        if (response.status === 200) {
          this.success = true;
          setTimeout(() => {
            this.$router.push({ name: 'Category' });
          }, 2000);
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de produit :", error.message);
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 2rem auto;
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  padding: 1.5rem;
}

.card-body {
  padding: 2rem;
}

.form-label {
  font-weight: 600;
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 0.75rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.alert {
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.btn-danger {
  background-color: #dc3545;
  border: none;
}

.btn-danger:hover {
  background-color: #c82333;
}
</style>