<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3 class="text-center">Ajouter un produit</h3>
      </div>
      <div class="card-body">
        <div v-if="success" class="alert alert-success" role="alert">
          <i class="fas fa-check-circle"></i> Produit ajouté avec succès !
        </div>
        <div>
          <form @submit.prevent="addProduit" enctype="multipart/form-data">
            <div class="mb-3">
              <label for="libelle" class="form-label">Libellé</label>
              <input type="text" class="form-control" id="libelle" v-model="produit.libelle" placeholder="Entrez le libellé" required>
            </div>
            
            <div class="mb-3">
              <label for="description" class="form-label">Description</label>
              <textarea class="form-control" id="description" v-model="produit.description" rows="3" placeholder="Entrez la description" required></textarea>
            </div>

            <div class="mb-3">
              <label for="prix" class="form-label">Prix</label>
              <input type="number" class="form-control" id="prix" v-model="produit.prix" placeholder="Entrez le prix" required>
            </div>

            <div class="mb-3">
              <label for="images" class="form-label">Images</label>
              <input type="file" class="form-control" id="images" @change="handleFileUpload" accept="image/*" multiple>
            </div>
            <button type="submit" class="btn btn-primary w-100">Ajouter le produit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/Axiosauth';
export default {
  data() {
    return {
      success: false,
      produit: {
        libelle: "",
        description: "",
        images: [],
        dispo: 1,
        prix: 0
      }
    };
  },
  methods: {
    async addProduit() {
      let formData = new FormData();

      formData.append('libelle', this.produit.libelle);
      formData.append('description', this.produit.description);
      formData.append('dispo', this.produit.dispo);
      formData.append('id_categorie', this.$route.params.id_categorie);
      formData.append('id_sous_categorie', this.$route.params.id_sous_categorie);
      formData.append('prix', this.produit.prix);

      if (this.produit.images && this.produit.images.length > 0) {
        this.produit.images.forEach((image, index) => {
          formData.append(`images[${index}]`, image);
        });
      }

      try {
        const response = await axiosInstance.post('produits', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.success) {
          this.success = true;
          alert(response.data.message);
          this.$router.push({ name: 'ShowProduitIn', params: { id_sous_categorie: this.$route.params.id_sous_categorie ,idCategory:this.$route.params.id_categorie} });

        }
      } catch (error) {
        console.error('Error adding product:', error.response ? error.response.data : error.message);
        alert('Une erreur est survenue lors de l\'ajout du produit.');
      }
    },
    handleFileUpload(event) {
      this.produit.images = Array.from(event.target.files);
    }
  }
};

</script>

<style scoped>
.container {
  max-width: 500px;
  margin: 2rem auto;
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  padding: 1.5rem;
}

.card-body {
  padding: 2rem;
}

.form-label {
  font-weight: 600;
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 0.75rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.alert {
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}
</style>
