<template>
  <div v-if="isOpen" class="fixed inset-0 overflow-hidden z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="absolute inset-0 overflow-hidden">
      <!-- Overlay de fond -->
      <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="closeCart"></div>

      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
        <div class="pointer-events-auto w-screen max-w-md">
          <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div class="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
              <div class="flex items-start justify-between">
                <h2 id="slide-over-title" class="custom m-auto">سلة المشتريات</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button type="button" class="relative m-2 p-2 text-gray-400 hover:text-gray-500" @click="closeCart">
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="mt-8">
                <div class="flow-root">
                  <ul role="list" class="-my-6 divide-y divide-gray-200">
                    <li v-for="item in cartItems" :key="item.id_produit || item.id_paquet" class="flex py-6">
                      <div v-if="item.images && item.images.length && item.images[0].image_path" class="h-24 w-24 flex-shrink-0 ml-4 overflow-hidden rounded-md border border-gray-200">
                        <img  :src="`${item.images[0].image_path}`" class="h-full w-full object-cover object-center">
                      </div>
                      <div class="ml-4 flex flex-1 flex-col">
                        <div>
                          <div class="flex justify-between text-base font-medium text-gray-900">
                            <h3 class="custom-libelle">
                              <a href="#"> {{ item.libelle }} </a>
                            </h3>
                            <p class="custom-total">{{ item.prix * item.quantite }} درهم</p>
                          </div>
                        </div>
                        <div class="flex flex-1 items-end justify-between text-sm">
                          <div class="custom-quantite flex items-center">
                            <button type="button" @click="decrementQuantity(item)"
                              class="px-2 py-1 bg-gray-200 rounded-l hover:bg-gray-300"
                              aria-label="Diminuer la quantité">-</button>
                            <span class="mx-2"> الكمية : {{ item.quantite }}</span>
                            <button type="button" @click="incrementQuantity(item)"
                              class="px-2 py-1 bg-gray-200 rounded-r hover:bg-gray-300"
                              aria-label="augumenter la quantité">+</button>
                          </div>

                          <div :class="{ 'custom-quantite': item.ispaquet }" class="flex">
                            <button @click="removeItem(item)" type="button"
                              class="custom-quantite font-medium text-emerald-600 hover:text-emerald-500">
                              {{ item.ispaquet ? 'إزالة العرض' : 'إزالة المنتوج' }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
              <div class="flex justify-between text-base font-medium text-gray-900">
                <p class="custom-total">المجموع </p>
                <p class="custom-total"> {{ cartTotal }} درهم </p>
              </div>
              <div class="mt-6">
                <button type="button" @click="goToConfirm"
                  class="custom-button bg-[#50B498] text-white w-full px-4 py-2 m-auto rounded hover:bg-[#468585] transition duration-300">
                  أطلب ألآن
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCartStore } from '@/useCartStore';

export default {
  name: 'CartList',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cart: useCartStore(),
      isOpen: false,
    };
  },
  computed: {
    cartItems() {
      return this.cart.items;
    },
    cartTotal() {
      return this.cart.total;
    }
  },
  watch: {
    show(val) {
      this.isOpen = val;
    }
  },
  methods: {
    incrementQuantity(item) {
      this.cart.updateQuantity(item, item.quantite + 1);
    },
    decrementQuantity(item) {
      if (item.quantite > 1) {
        this.cart.updateQuantity(item, item.quantite - 1);
      }
    },
    removeItem(item) {
      this.cart.remove(item);
    },
    closeCart() {
      this.isOpen = false;
      this.cart.closeCart();
    },
    goToConfirm() {
      this.cart.closeCart(),
      this.$router.push({ name: 'Confirm' });
    }
  },
  mounted() {
    this.isOpen = this.show;
  },
  beforeUnmount() {
    this.cart.closeCart();
  }
};
</script>

<style>
.relative {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.custom {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 2rem);
  color: #50B498;
  text-align: center;
}

.custom-total {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 1.4rem);
  color: #468585;
  text-align: center;
}

.custom-title {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 2rem);
  color: #468585;
}

.custom-button {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 1.5rem);
  margin: auto;
  margin-right: 50%;
  color: #468585;
}

@media (max-width: 640px) {
  .custom-button {
    width: 100%;
    margin-right: 0;
    padding: 0.75rem;
  }
}

.custom-libelle {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 1.3rem);
  color: #468585;
}

.custom-quantite {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 1rem);
  color: #468585;
}
</style>