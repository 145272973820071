<template>
  <div class="min-w-[300px] min-h-[600px]">
    <div dir="rtl" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <router-link v-if="isAdmin" :to="{ name: 'AddProduitToPaquet', params: { id: $route.params.id } }">
        <button type="button" class="mb-4 btn btn-info">Ajouter Produit</button>
      </router-link>
      <div v-if="isLoading" class="flex justify-center items-center h-64">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>

      <div v-else-if="error" class="text-center text-red-600">
        <p>{{ error }}</p>
      </div>

      <div v-else>
        <Swiper :modules="modules" :slides-per-view="1" :space-between="5" :centered-slides="true"
          :pagination="{ clickable: true }" :navigation="false" :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
          }" class="mySwiper h-screen max-h-[300px]">
          <SwiperSlide v-for="(image, index) in paquet.images" :key="index">
            <div class="transform transition duration-500 hover:scale-105 h-full">
              <img :src="`${image.image_path}`" loading="lazy" class="rounded-lg w-full h-full object-cover shadow-lg" />
            </div>
          </SwiperSlide>
        </Swiper>
        <!-- Détails du package -->
        <div class="text-center mt-6 mb-12">
          <h2 class="custom text-4xl font-bold mb-4">{{ paquet.libelle }}</h2>
          <p class="custom text-xl">{{ paquet.description }}</p>
        </div>

        <!-- Produits inclus dans le package -->
        <div class="mt-16">
          <h3 class="custom text-2xl font-semibold mb-6">المنتوجات :</h3>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
            <ProductCard v-for="product in produits" :key="product.id_produit" :product="product" :id_paquet="$route.params.id" @deletedproduitId="handleproduitDeleted" />
          </div>
        </div>

        <div dir="rtl" class="custom mt-12 text-center">
          <div v-if="promotion > 0" class="text-xl font-bold text-red-600">
            تخفيض : {{ promotion.toFixed(2) }} درهم
          </div>
          <p class="text-2xl font-bold">المجموع : {{ paquet.prix }} درهم</p>
        </div>
        <div class="mt-6 space-y-4">
          <button @click="addToCart(paquet)"
            class="custom w-full bg-lime-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-lime-600 transition duration-300 ease-in-out flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            إضافة في السلة
          </button>
          <button @click="byNow(paquet)"
            class="custom w-full bg-green-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out">
            إشتري الأن
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ProductCard from '@/components/ProductCard.vue';
import { mapGetters } from 'vuex';
import { useCartStore } from '@/useCartStore';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  components: {
    ProductCard,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      paquet: null,
      produits: null,
      isLoading: true,
      error: null,
      currentIndex: 0,
      modules: [Navigation, Pagination, Autoplay],
      cart: useCartStore()
    };
  },
  computed: {
    promotion() {
      if (!this.produits || Object.keys(this.produits).length === 0) {
        return 0;
      }
      const sommeProduitsIndividuels = Object.values(this.produits).reduce((sum, prod) => sum + Number(prod.prix), 0);
      return Math.max(0, sommeProduitsIndividuels - (this.paquet ? this.paquet.prix : 0));
    },
    ...mapGetters(['isAdmin']),
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        this.fetchData();
      }
    },
    produits: {
      deep: true,
      handler(newProduits) {
        if (newProduits && Object.keys(newProduits).length > 0) {
          this.$forceUpdate();
        }
      }
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.error = null;
      try {
        await Promise.all([this.getPaquetDetaille(), this.getProducts()]);
      } catch (error) {
        this.$logErrorToBackend("error fetching data", error.message);
        this.error = "حدث خطأ أثناء تحميل الصفحة المرجو إعاد المحاولة";
      } finally {
        this.isLoading = false;
      }
    },
    async getPaquetDetaille() {
      const url = `paquets/${this.$route.params.id}`;
      const response = await axios.get(url);
      this.paquet = response.data?.data || {};
    },
    async getProducts() {
      const url = `show/produit/paquet/${this.$route.params.id}`;
      const response = await axios.get(url);
      this.produits = response?.data || [];
    },
    prevSlide() {
      if (this.paquet && this.paquet.images) {
        this.currentIndex = (this.currentIndex - 1 + this.paquet.images.length) % this.paquet.images.length;
      }
    },
    nextSlide() {
      if (this.paquet && this.paquet.images) {
        this.currentIndex = (this.currentIndex + 1) % this.paquet.images.length;
      }
    },
    handleproduitDeleted(deletedproduitId) {
      this.produits = this.produits.filter(produit => produit.id_produit !== deletedproduitId);
    },
    addToCart(paquet) {
      this.cart.add(paquet, 1, true);
    },
    byNow(paque) {
      this.cart.add(paque, 1, true);
      this.cart.openCart();
    }
  },
  created() {
    this.fetchData();
  }
};
</script>
<style>
.custom {
  font-family: 'Arabswell', serif;
  font-size: clamp(1rem, 3vw, 1.7rem);
  color: #0c3a14;
}


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>