<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3 class="text-center">Ajouter un produit</h3>
      </div>
      <div class="card-body">
        <div v-if="success" class="alert alert-success" role="alert">
          <i class="fas fa-check-circle"></i> Produit ajouté avec succès !
        </div>
        <div v-if="categories.length > 0">
          <form @submit.prevent="addProduit" enctype="multipart/form-data">
            <div class="mb-3">
              <label for="libelle" class="form-label">Libellé</label>
              <input type="text" class="form-control" id="libelle" v-model="produit.libelle" placeholder="Entrez le libellé" required>
            </div>
            
            <div class="mb-3">
              <label for="description" class="form-label">Description</label>
              <textarea class="form-control" id="description" v-model="produit.description" rows="3" placeholder="Entrez la description" required></textarea>
            </div>

            <div class="mb-3">
              <label for="prix" class="form-label">Prix</label>
              <input type="number" class="form-control" id="prix" v-model="produit.prix" placeholder="Entrez le prix" required>
            </div>

            <div class="mb-3">
              <label for="categorie" class="form-label">Catégorie</label>
              <select class="form-control" id="categorie" v-model="selectedCategorie" required>
                <option value="" disabled selected>Sélectionnez une catégorie</option>
                <option v-for="categorie in categories" :key="categorie.id_categorie" :value="categorie.id_categorie">
                  {{ categorie.libelle }}
                </option>
              </select> 
            </div>

            <div class="mb-3">
              <label for="sous-categorie" class="form-label">Sous-catégorie</label>
              <select class="form-control" id="sous-categorie" v-model="selectedSousCategorie" required>
                <option value="" disabled selected>Sélectionnez une sous-catégorie</option>
                <option v-for="sousCategorie in sousCategories" :key="sousCategorie.id_sous_categorie" :value="sousCategorie.id_sous_categorie">
                  {{ sousCategorie.libelle }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="images" class="form-label">Images</label>
              <input type="file" class="form-control" id="images" @change="handleFileUpload" accept="image/*" multiple>
            </div>
            <div v-if="resizedImages.length" class="mb-3">
              <p>Images redimensionnées :</p>
              <ul>
                <li v-for="(image, index) in resizedImages" :key="index">
                  {{ image.name }} - {{ formatFileSize(image.size) }}
                </li>
              </ul>
            </div>
            <button type="submit" class="btn btn-primary w-100">Ajouter le produit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/Axiosauth';
import axios from 'axios';

export default {
  data() {
    return {
      success: false,
      categories: [],
      sousCategories: [],
      selectedCategorie: null,
      selectedSousCategorie: null,
      VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL,
      produit: {
        libelle: "",
        description: "",
        images: [],
        dispo: 1,
        prix: 0
      },
      resizedImages: []
    };
  },
  mounted() {
    this.getCategories();
  },
  watch: {
    selectedCategorie(newVal) {
      if (newVal) {
        this.getSousCategories(newVal);
      }
    }
  },
  methods: {
    async getCategories() {
      try {
        const response = await axios.get('categories');
        this.categories = response.data.data.data;
      } catch (error) {
        this.$logErrorToBackend("Error fetching categories ", error.message);
      }
    },
    async getSousCategories(categorieId) {
      try {
        const response = await axios.get(`show/category/souscategory/${categorieId}`);
        this.sousCategories = response.data;
      } catch (error) {
        this.$logErrorToBackend("Error fetching sous categories ", error.message);
      }
    },
    async addProduit() {
      const url = "produits";
      let formData = new FormData();

      formData.append('libelle', this.produit.libelle);
      formData.append('description', this.produit.description);
      formData.append('dispo', this.produit.dispo);
      formData.append('id_categorie', this.selectedCategorie);
      formData.append('id_sous_categorie', this.selectedSousCategorie);
      formData.append('prix', this.produit.prix);

      this.resizedImages.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });

      try {
        const response = await axiosInstance.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.success) {
          this.success = true;
          setTimeout(() => {
            alert(response.data.message);
          }, 500);
        }
      } catch (error) {
        this.$logErrorToBackend("Une erreur est survenue lors de l'ajout du produit", error.message);
        alert('Une erreur est survenue lors de l\'ajout du produit.');
      }
    },
    async handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.resizedImages = [];

      for (const file of files) {
        const resizedImage = await this.resizeImage(file);
        this.resizedImages.push(resizedImage);
      }
    },
    resizeImage(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
            
            // Redimensionner si l'image est trop grande
            const MAX_WIDTH = 1024;
            const MAX_HEIGHT = 1024;
            
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            
            canvas.toBlob((blob) => {
              const resizedFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              });
              resolve(resizedFile);
            }, 'image/jpeg', 0.7); // Qualité de compression à 70%
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    formatFileSize(bytes) {
      if(bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 500px;
  margin: 2rem auto;
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  padding: 1.5rem;
}

.card-body {
  padding: 2rem;
}

.form-label {
  font-weight: 600;
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 0.75rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.alert {
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}
</style>
