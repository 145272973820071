<template>
  <div dir="rtl" class="about-container bg-[#b8f1e5] text-black p-8 rounded-lg shadow-lg">
    <!-- Phrase de description -->
    <div class="description text-right mb-10">
      <p class="font-bold text-2xl leading-relaxed">
        متجر العلمي هو وجهتك المثالية للملابس التقليدية حيث نجمع بين فخر تجاوز العشرين عاماً والالتزام دائم بتلبية ذوق عملائنا بأفضل الأسعار.
      </p>
    </div>
    
    <!-- Section "عن التجر" -->
    <div class="about-section mb-10">
      <h2 class="text-2xl font-semibold mb-6 text-right border-b border-green-500 pb-2">عن التجر</h2>
      <ul class="text-right space-y-4">
        <li class="flex items-center">
          <svg class="w-6 h-6 ml-2 text-green-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span>طرق الدفع : يعتمد متجر العلمي مع زبنائه طريقة الدفع عند الإستلام</span>
        </li>
        <li class="flex items-center">
          <svg class="w-6 h-6 ml-2 text-green-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>  
          <span>التوصيل : متجر العلمي يوفر إمكانية التوصيل لجميع أنحاء المغرب عبر شركة  DIGYLOG</span>
        </li>
        <li class="flex items-center">
          <svg class="w-6 h-6 ml-2 text-green-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span> يمكن للزبون أن يتابع سلعته بكل سهولة عبر موقع الشركة</span>
        </li>
      </ul>
    </div>
    
    <!-- Section de politique de retour -->
    <div class="return-policy-section mb-10">
      <h2 class="text-2xl font-semibold mb-6 text-right border-b border-green-500 pb-2">سياسة الإرجاع</h2>
      <p class="text-right leading-relaxed">
        يحق للزبون إرجاع المنتجات التي تم طلبها خلال مدة 7 أيام من تاريخ استلامها للحصول على تعويض أو استبدال.
      </p>
      <p class="text-right leading-relaxed">
       يجب أن تكون المنتجات المعادة في حالتها الأصلية، غير مغسولة، وغير ملبوسة، وفي العبوة الأصلية، لكي تكون مؤهلة للتعويض أو الاستبدال</p>
      <p class="text-right leading-relaxed">
        لإجراء عملية الإرجاع، يرجى إبلاغنا عبر الواتساب أو الإتصال بنا، يجب أن يتضمن كل طلب إرجاع أو استبدال رقم هاتف الزبون ورغبته في الاستبدال. تكاليف الإرجاع تقع على عاتق الزبون .  في حالة الإرجاع وبعد استلام المنتجات والتأكد من سلامتها، سيتم تعويض العميل في غضون 10 أيام.  الحالات التي يمكن فيها استبدال المنتجات:  في حالة خطأ في المقاس المطلوب (المقاس المستلم يختلف عن المقاس المطلوب). في حالة خطأ في اللون المطلوب (اللون المستلم يختلف عن اللون المطلوب). الحالات التي يمكن فيها استرداد المبالغ:  خطأ في المقاس أو اللون المطلوب مع عدم توفر المنتج في المخزون. في هذه الحالات، يجب إرجاع المنتجات بنفس الحالة التي استلمت بها مع جميع العناصر المرفقة (ملحقات، تغليف، ...). سيتم التعويض إما عن طريق التحويل البنكي أو الإيداع.  
      </p>
      <p class="text-right leading-relaxed">
    الحالات التي يمكن فيها استبدال المنتجات:  في حالة خطأ في المقاس المطلوب (المقاس المستلم يختلف عن المقاس المطلوب). في حالة خطأ في اللون المطلوب (اللون المستلم يختلف عن اللون المطلوب). الحالات التي يمكن فيها استرداد المبالغ:  خطأ في المقاس أو اللون المطلوب مع عدم توفر المنتج في المخزون. في هذه الحالات، يجب إرجاع المنتجات بنفس الحالة التي استلمت بها مع جميع العناصر المرفقة (ملحقات، تغليف، ...). سيتم التعويض إما عن طريق التحويل البنكي أو الإيداع.  
      </p>
      <!-- Add more details about return policy here -->
    </div>

    <!-- Section des réseaux sociaux -->
    <div class="social-media-section">
      <h2 class="text-2xl font-semibold mb-6 text-right border-b border-green-500 pb-2">للتواصل معنا</h2>
      <div class="flex justify-end space-x-6 rtl:space-x-reverse text-right">
        <div class="flex items-center">
          <span class="ml-2">0677778110</span>
          <svg class="w-6 h-6 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
        </div>
        <div class="flex items-center">
          <span class="ml-2">0682011593</span>
          <svg class="w-6 h-6 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped>
.about-container {
  font-family: 'Arabswell', serif;
}

.text-right {
  direction: rtl;
}

/* Add some hover effects for interactivity */
.about-section li:hover,
.social-media-section .flex div:hover {
  transform: translateX(-5px);
  transition: transform 0.3s ease;
}
</style>