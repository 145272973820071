<template>
  <div class="min-h-[600px]">
  <div class="container mx-auto px-4">
    <div v-if="isLoading" class="flex justify-center items-center h-64">
    <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
    <div v-else-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 my-4" role="alert">
      {{ error }}
    </div>
    <div v-else>
      <div dir="rtl" v-if="isAdmin" class="mt-4">
          <router-link :to="{ name: 'AddProduitIn', params: { id_sous_categorie: this.$route.params.id_sous_categorie, id_categorie: this.$route.params.idCategory }}">
            <button type="button"
              class="flex items-center gap-2 bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd" />
              </svg>
             إضافة
            </button>
          </router-link>
        </div>
      <div v-if="produits.length > 0" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-10">
        <div v-for="produit in produits" :key="produit.id_produit"
          class="product-card bg-white rounded-lg shadow-lg overflow-hidden">
          <div class="swiper-container mb-10">
            <Swiper :effect="'cube'" :grabCursor="true" :cubeEffect="{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 0.94,
            }" :pagination="{ clickable: true }" :navigation="false" :modules="swiperModules" :autoplay="{
                delay: 1500,
                disableOnInteraction: false,
              }" class="h-full">
              <SwiperSlide v-for="image in produit.images" :key="image.id" class="h-full">
                <img :src="`${image.image_path}`" class="w-full h-full object-cover">
              </SwiperSlide>
            </Swiper>
          </div>
          <div dir="rtl" class="items-center">
            <h3 class="custom text-xl font-semibold mb-2">{{ produit.libelle }}</h3>
            <!-- <div>
              <p class="custom text-gray-600 mb-2">{{ produit.description.substring(0, 30) + "..." || "Pas de description disponible." }}</p>
            </div> -->
            <p class="custom text-lg font-bold text-green-600 mb-2"> {{ produit.prix }} درهم</p>
            <p v-if="produit.dispo === 0" class="text-red-500 font-semibold mb-2">Alert de stock</p>
            <div class="flex sm: justify-center lg:justify-between w-full items-center mb-6 p-4">
              <router-link :to="{ name: 'ProductDetaille', params: { id: produit.id_produit } }"
                class="custom bg-[#50B498] lg:px-1 text-white sm:w-full py-2 px-4 rounded">
                المزيد
              </router-link>
              <button v-if="isAdmin" @click="deleteproduit(produit.id_produit)"
                class="bg-red-500 lg:px-1 text-white sm:w-full py-3 px-3 rounded">
                حذف
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex justify-center">
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <a href="#" @click.prevent="changePage(currentPage - 1)" :class="['relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50']">
            &laquo;
          </a>
          <a v-for="page in paginationRange" :key="page" href="#" @click.prevent="changePage(page)"
            :class="['relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium',
              currentPage === page ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600' : 'text-gray-500 hover:bg-gray-50']">
            {{ page }}
          </a>
          <a href="#" @click.prevent="changePage(currentPage + 1)" :class="['relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50']">
            &raquo;
          </a>
        </nav>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCube, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { mapGetters } from 'vuex';
import axios from 'axios';
import axiosInstance from '@/Axiosauth';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

export default {
  name: 'CategoryShow',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      produits: [],
      isLoading: true,
      error: null,
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 20,
      VUE_APP_SERVICE_URL : process.env.VUE_APP_SERVICE_URL,
      deleted: false,
      swiperModules: [EffectCube, Pagination, Navigation, Autoplay],
    };
  },
  computed: {
    paginationRange() {
      const range = [];
      const rangeSize = 5; // Nombre de pages à afficher dans la pagination
      let start = Math.max(1, this.currentPage - Math.floor(rangeSize / 2));
      let end = Math.min(this.totalPages, start + rangeSize - 1);

      if (end - start + 1 < rangeSize) {
        start = Math.max(1, end - rangeSize + 1);
      }

      for (let i = start; i <= end; i++) {
        range.push(i);
      }
      return range;
    },
    ...mapGetters(['isAdmin']),
  },
  mounted() {
    this.getProduitsInSousCategory();
  },
  methods: {
    async getProduitsInSousCategory() {
      const id = this.$route.params.id_sous_categorie;
      const url = `show/produit/souscategory/${id}`;
      try {
        const response = await axios.get(url);
        if (response.data && response.data.length > 0) {

          this.produits = response.data;
          this.currentPage = response.data.current_page;
          this.totalPages = response.data.last_page;
        } else {
          this.produits = [];
        }
      } catch (error) {
        this.$logErrorToBackend("Une erreur est survenue lors du chargement des produits.",error.message);
        this.error = "حدث حطأ أثناء تحميل الصفحة المرجو إعاد المحاولة";
      } finally {
        this.isLoading = false;
      }
    },
    async changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.isLoading = true;
        await this.getProduitsInSousCategory();
      }
    },
    async deleteproduit(id) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce produit ?")) {
        try {
          await axiosInstance.delete(`produits/${id}`);
          alert("Produit supprimée avec succès !");
          this.handleproduitDeleted(id);
        } catch (error) {
          this.$logErrorToBackend("Erreur lors de la suppression de produit.",error.message);
          alert("Erreur lors de la suppression de produit.");
        }
      }
    },
    handleproduitDeleted(deletedproduitId) {
      // Filtrer la catégorie supprimée de la liste
      this.produits = this.produits.filter(produit => produit.id_produit !== deletedproduitId);
    }
  }
};
</script>

<style scoped>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.product-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.swiper-container {
  height: 450px;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom {
  font-family: 'Arabswell', serif;
}
</style>