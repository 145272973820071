<template>
  <div class="min-w-[300px] min-h-[400px]">
    <div v-if="isLoading" class="flex justify-center items-center h-64">
      <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
    <div v-else-if="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
    <div v-if="isAdmin" class="container">
      <div>
        <button @click="logoutA" class="btn btn-info">
          <i class="bi bi-box-arrow-right"></i> <!-- Icône de déconnexion -->
          <p>logout</p>
        </button>
        <div class="flex justify-end mt-6">
          <router-link :to="{ name: 'AddProduit' }" type="button"
            class="items-end bg-emerald-500 hover:bg-emerald-600 text-white font-bold rounded shadow-md hover:shadow-lg transition duration-300 px-4 py-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clip-rule="evenodd" />
            </svg>
            Ajouter Produit
          </router-link>
          <router-link :to="{ name: 'image' }" type="button"
            class=" ml-4 items-end bg-[#50B498] hover:bg-emerald-600 text-white font-bold rounded shadow-md hover:shadow-lg transition duration-300 px-4 py-1">
            images slide</router-link>
        </div>
        <table class="w-full text-left border-collapse mt-8 font-bold">
          <thead>
            <tr>
              <th class="border p-2">Date Commande</th>
              <th class="border p-2">Total</th>
              <th class="border p-2">Statut</th>
              <th class="border p-2">statu cash</th>
              <th class="border p-2">payé le</th>
              <th class="border p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(commande, index) in commandes" :key="commande.id_commande">
              <tr>
                <td class="border p-2">
                  <i> {{ commande.date_commande }} </i>
                </td>

                <td class="border p-2">{{ commande.total }}</td>

                <td class="border p-2">
                  <i> {{ commande.statut }}</i>
                  <button @click="openModal(commande.id_commande, 'statut')"
                    class="bg-blue-500 flex justify-end text-white px-2 py-1 rounded">
                    Modifier
                  </button>
                </td>
                <td class="border p-2">
                  {{ commande.statut_cash }}
                  <button @click="openModal(commande.id_commande, 'statut_cash')"
                    class="bg-blue-500 flex justify-end text-white px-2 py-1 rounded">
                    Modifier
                  </button>
                </td>

                <td class="border p-2">
                  {{ commande.date_payement || 'pas encore' }}
                  <button @click="openModal(commande.id_commande, 'date_payement')"
                    class="bg-blue-500 text-white flex justify-end px-2 py-1 rounded">
                    Modifier date
                  </button>
                </td>
                <div v-if="showModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
                  id="my-modal">
                  <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                    <div class="absolute top-0 right-0 mt-4 mr-4">
                      <button @click="closeModal" class="text-gray-400 hover:text-gray-600">
                        <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                      </button>
                    </div>
                    <div class="mt-3 text-center">
                      <h3 class="text-lg leading-6 font-medium text-gray-900">Modifier {{ editingField }}</h3>
                      <div class="mt-2 px-7 py-3">
                        <input v-if="editingField === 'date_payement'" type="date" v-model="tempValue"
                          class="w-full px-3 py-2 border rounded-md">
                        <select v-else v-model="tempValue" class="w-full px-3 py-2 border rounded-md">
                          <option v-if="editingField === 'statut'" value="en cours">En cours</option>
                          <option v-if="editingField === 'statut'" value="livré">Livré</option>
                          <option v-if="editingField === 'statut'" value="annulé">Annullé</option>
                          <option v-if="editingField === 'statut_cash'" value="payé">Payé</option>
                          <option v-if="editingField === 'statut_cash'" value="non payé">non payé</option>
                          <option v-if="editingField === 'statut_cash'" value="en attente">en attente</option>
                        </select>
                      </div>
                      <div class="items-center px-4 py-3">
                        <button @click="saveChanges"
                          class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
                          Sauvegarder
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <td class="border p-2">
                  <button @click="toggleDetails(index, 'client')" class="bg-blue-500 text-white px-2 py-1 rounded mr-2">
                    Détails Client
                  </button>
                  <button @click="toggleDetails(index, 'commande')" class="bg-green-500 text-white px-2 py-1 rounded">
                    Détails Commande
                  </button>
                </td>
              </tr>
              <tr v-if="expandedRow === index">
                <td colspan="7" class="border p-2">
                  <table v-if="detailType === 'client'" class="w-full text-left border-collapse bg-gray-100">
                    <thead>
                      <tr>
                        <th class="border p-2">Nom Client</th>
                        <th class="border p-2">Téléphone</th>
                        <th class="border p-2">Adresse</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border p-2">{{ commande.client.nom }} {{ commande.client.prenom }}</td>
                        <td class="border p-2">{{ commande.client.tel }} </td>
                        <td class="border p-2">{{ commande.client.adresse }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table v-else-if="detailType === 'commande'" class="w-full text-left border-collapse bg-gray-100">
                    <thead>
                      <tr>
                        <th class="border p-2">Produit</th>
                        <th class="border p-2">Prix</th>
                        <th class="border p-2">Quantité</th>
                        <th class="border p-2">Sous-total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in commande.items" :key="item.id_ligne">
                        <td class="border p-2">{{ item.item.libelle }} </td>
                        <td class="border p-2">{{ item.item.prix }} DH</td>
                        <td class="border p-2">{{ item.quantite }}</td>
                        <td class="border p-2">{{ item.item.prix * item.quantite }} DH</td>
                      </tr>
                      <p class="text-center"> descroption: </p> <br>
                      {{ commande.message }}
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axiosInstance from '@/Axiosauth';
export default {
  components: { Dropdown },
  data() {
    return {
      commandes: [],
      error: null,
      isLoading: true,
      detailType: null,
      expandedRow: null,
      editingCommandeId: null,
      editingField: null,
      tempValue: null,
      showModal: false,
    };
  },
  mounted() {
    this.getCommandes();
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  methods: {
    async getCommandes() {
      try {
        const response = await axiosInstance.get('commandes');
        if (response.data) {
          this.commandes = response.data;
        }
      } catch (error) {
        this.$logErrorToBackend("Error fetching commandes", error.message);
        this.error = "Une erreur est survenue lors du chargement des commandes.";
      } finally {
        this.isLoading = false;
      }
    },

    async updateStatut(id, newStatut) {
      try {
        const response = await axiosInstance.put(`commande/update/statut`, {
          id_commande: id,
          statut: newStatut
        });
        if (response.data.success) {
          const commande = this.commandes.find(c => c.id === id || c.id_commande === id);
          if (commande) {
            commande.statut = newStatut;
          }
          alert('Statut mis à jour avec succès');
        }
      } catch (error) {
        this.$logErrorToBackend("Error lors de la modification de statut ", error.message);
        alert('Erreur lors de la mise à jour du statut. Vérifiez la console pour plus de détails.');
      }
    },

    async updateStatutCash(id, newStatutCash) {
      try {
        const response = await axiosInstance.put(`commande/update/statut_cash`, {
          id_commande: id,
          statut_cash: newStatutCash
        });
        if (response.data.success) {
          const commande = this.commandes.find(c => c.id === id || c.id_commande === id);
          if (commande) {
            commande.statut_cash = newStatutCash;
          }
          alert('Statut cash mis à jour avec succès');
        }
      } catch (error) {
        this.$logErrorToBackend("Error lors de la modification de statut cash", error.message);
        alert('Erreur lors de la mise à jour du statut cash.');
      }
    },

    async updateDatePayement(id, newDate) {
      try {
        const payload = {
          id_commande: id,
          date_payement: newDate,
        };
        const response = await axiosInstance.put('commande/update/date_payement', payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.data.success) {
          const commande = this.commandes.find(c => c.id === id || c.id_commande === id);
          if (commande) {
            commande.date_payement = newDate;
          }
          alert('Date de paiement mise à jour avec succès');
        }
      } catch (error) {
        this.$logErrorToBackend("Error lors de la modification de datede payement", error.message);
        alert('Erreur lors de la mise à jour de la date de paiement');
      }
    },
    openModal(id, field) {
      this.editingCommandeId = id;
      this.editingField = field;
      const commande = this.commandes.find(c => c.id === id || c.id_commande === id);
      if (field === 'date_payement') {
        this.tempValue = commande[field] ? new Date(commande[field]).toISOString().split('T')[0] : '';
      } else {
        this.tempValue = commande[field];
      }
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      this.editingCommandeId = null;
      this.editingField = null;
      this.tempValue = null;
    },

    async saveChanges() {
      if (this.editingCommandeId && this.editingField) {
        try {
          if (this.editingField === 'date_payement') {
            await this.updateDatePayement(this.editingCommandeId, this.tempValue);
          } else if (this.editingField === 'statut') {
            await this.updateStatut(this.editingCommandeId, this.tempValue);
          } else if (this.editingField === 'statut_cash') {
            await this.updateStatutCash(this.editingCommandeId, this.tempValue);
          }
          this.closeModal();
          this.$forceUpdate();
        } catch (error) {
          this.$logErrorToBackend("Error lors de la sauvegarde", error.message);
        }
      }
    },

    toggleDetails(index, type) {
      if (this.expandedRow === index && this.detailType === type) {
        this.expandedRow = null;
        this.detailType = null;
      } else {
        this.expandedRow = index;
        this.detailType = type;
      }
    },
    async logoutA() {
      if (confirm("Êtes-vous sûr de vouloir vous déconnecter ?")) {
        try {
          const response = await axiosInstance.post('logout');
          if (response.status === 200) {
            this.logout();
            this.$router.push('/');
            alert("Au revoir !");
          }
        } catch (error) {
          this.$logErrorToBackend("Error lors de logout", error.message);
        }
      }
    },

    ...mapActions(['logout']),
  },
}
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 500px;
  width: 500px;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
}
</style>