import { createStore } from 'vuex';
import axiosInstance from './Axiosauth';
export default createStore({
  state: {
    isAdmin: false,
    currentUser: null,
    token: localStorage.getItem('token') || null,  // Récupère le token du localStorage
  },
  mutations: {
    setAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);  // Stocke le token dans localStorage
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    clearAuth(state) {
      state.isAdmin = false;
      state.currentUser = null;
      state.token = null;
      localStorage.removeItem('token');  // Supprime le token du localStorage
    },
  },
  actions: {
    loginAsAdmin({ commit }, { user, token }) {
      commit('setCurrentUser', user);
      commit('setAdmin', true);
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('clearAuth');
    },
    async checkAuth({ commit }) {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axiosInstance.get('/user', {
            headers: { Authorization: `Bearer ${token}` },
          });
          commit('setCurrentUser', response.data);
          commit('setAdmin',true); // Supposez que la réponse contient isAdmin
        } catch (error) {
          console.error("Erreur d'authentification:", error);
          commit('clearAuth');
        }
      } else {
        commit('clearAuth');
      }
    },
  },
  getters: {
    isAdmin: (state) => state.isAdmin,
    currentUser: (state) => state.currentUser,
    token: (state) => state.token,
    isAuthenticated: (state) => !!state.token,
  },
});
