<template>
   <div class="min-h-[600px]">
  <div class="container">
    <div v-if="isLoading" class="flex justify-center items-center h-64">
      <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
    <div v-else-if="error" class="alert alert-danger " role="alert">
      {{ error }}
    </div>
      <div class="row">
        <div  class="mt-4">
          <router-link v-if="isAdmin" :to="{ name: 'AddCategory' }">
            <button type="button"
              class="flex items-center gap-2 bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd" />
              </svg>
              Ajouter Catégorie
            </button>
          </router-link>
        </div>
        <div v-for="category in categories" :key="category.id_categorie" class="col-md-4 mb-4 mt-20">
          <CategoryBox :category="category" @categoryDeleted="handleCategoryDeleted"></CategoryBox>
        </div>
      </div>
      <div>
        <ul class="pagination">
          <li :class="['page-item', currentPage === 1 ? 'disabled' : '']">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">&laquo;</a>
          </li>
          <li v-for="page in paginationRange" :key="page" :class="['page-item', currentPage === page ? 'active' : '']">
            <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
          <li :class="['page-item', currentPage === totalPages ? 'disabled' : '']">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">&raquo;</a>
          </li>
        </ul>
      </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
import CategoryBox from '@/components/Category/CategoryBox.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'CategoryShow',
  components: {
    CategoryBox,
  },
  data() {
    return {
      categories: [],
      isLoading: true,
      error: null,
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 20,
    };
  },
  computed: {
    paginationRange() {
      const range = [];
      const rangeSize = 5; // Nombre de pages à afficher dans la pagination
      let start = Math.max(1, this.currentPage - Math.floor(rangeSize / 2));
      let end = Math.min(this.totalPages, start + rangeSize - 1);

      if (end - start + 1 < rangeSize) {
        start = Math.max(1, end - rangeSize + 1);
      }

      for (let i = start; i <= end; i++) {
        range.push(i);
      }
      return range;
    },
    ...mapGetters(['isAdmin']),
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      const url = `categories?page=${this.currentPage}`;
      try {
        const response = await axios.get(url);
        if (response.data && response.data.data) {
          this.categories = response.data.data.data;
          this.currentPage = response.data.data.current_page;
          this.totalPages = response.data.data.last_page;
        } else {
          this.categories = [];
        }
      } catch (error) {
        this.$logErrorToBackend("Error fetching categories:",error.message);
        this.error = "Une erreur est survenue lors du chargement des catégories.";
      } finally {
        this.isLoading = false;
      }
    },
    async changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.isLoading = true;
        await this.getCategories();
      }
    },
    handleCategoryDeleted(deletedCategoryId) {
      this.categories = this.categories.filter(category => category.id_categorie !== deletedCategoryId);

      if (this.categories.length === 0 && this.currentPage > 1) {
        this.changePage(this.currentPage - 1);
      } else {
      this.getCategories();
      }
    }
  }
};
</script>

<style scoped>
.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
