<template>
  <div class="container min-h-[600px]">
    <div v-if="isLoading" class="flex justify-center items-center h-64">
      <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
    <div v-else-if="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
    <div v-if="isAdmin">
      <router-link :to="{ name: 'AddPaque' }">
        <button type="button" class="btn btn-info mt-4 mb-4">Ajouter Paque</button>
      </router-link>
    </div>
    <div v-if="paques.length > 0" class="row">
      <div class="row">
        <div v-for="paque in paques" :key="paque.id_paquet" class="col-md-4  mb-4">
          <div class="category-box">
            <div class="swiper-container">
              <Swiper :effect="'cube'" :grabCursor="true" :cubeEffect="{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
              }" :pagination="{ clickable: true }" :navigation="true" :modules="swiperModules" :autoplay="{
                    delay: 1500,
                    disableOnInteraction: false,
                  }" class="mySwiper">
                <SwiperSlide v-for="(image, index) in paque.images" :key="index">
                  <img :src="`${image.image_path}`" class="w-full h-full object-cover">
                </SwiperSlide>
              </Swiper>
            </div>
            <div dir="rtl" class="category-info">
              <h3 class="custom mt-6 mb-6">{{ paque.libelle }}</h3>
            </div>
            <!-- Utilisation de Flexbox pour aligner les boutons horizontalement -->
            <div class="btn-group ml-8 mb-4 mt-10" role="group" aria-label="Basic mixed styles example">
              <router-link :to="{ name: 'Paquedetaille', params: { id: paque.id_paquet } }">
                <button type="button" class="bg-[#50B498] text-white custom btn"> عرض التفاصيل</button>
              </router-link>
              <button v-if="isAdmin" type="button" class="btn btn-danger"
                @click="deletepaque(paque.id_paquet)">Supprimer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <ul class="pagination">
      <li :class="['page-item', currentPage === 1 ? 'disabled' : '']">
        <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">&laquo;</a>
      </li>
      <li v-for="page in paginationRange" :key="page" :class="['page-item', currentPage === page ? 'active' : '']">
        <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
      </li>
      <li :class="['page-item', currentPage === totalPages ? 'disabled' : '']">
        <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">&raquo;</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCube, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { mapGetters } from 'vuex';
import axios from 'axios';
import axiosInstance from '@/Axiosauth';

import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      paques: [],
      isLoading: true,
      error: null,
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 20,
      deleted: false,
      VUE_APP_SERVICE_URL: process.env.VUE_APP_SERVICE_URL,
      swiperModules: [EffectCube, Pagination, Navigation, Autoplay],
    };
  },
  computed: {
    paginationRange() {
      const range = [];
      const rangeSize = 5; // Nombre de pages à afficher dans la pagination
      let start = Math.max(1, this.currentPage - Math.floor(rangeSize / 2));
      let end = Math.min(this.totalPages, start + rangeSize - 1);

      if (end - start + 1 < rangeSize) {
        start = Math.max(1, end - rangeSize + 1);
      }

      for (let i = start; i <= end; i++) {
        range.push(i);
      }
      return range;
    },
    ...mapGetters(['isAdmin']),
  },
  mounted() {
    this.getPaques();
  },
  methods: {
    async getPaques() {
      const url = `paquets?page=${this.currentPage}`;
      try {
        const response = await axios.get(url);
        if (response.data && response.data.data) {
          this.paques = response.data.data.data;
          this.currentPage = response.data.data.current_page;
          this.totalPages = response.data.data.last_page;
        } else {
          this.paques = [];
        }
      } catch (error) {
        this.$logErrorToBackend("Error fetching paques", error.message);
        this.error = "error fetching paques";
      } finally {
        this.isLoading = false;
      }
    },
    async changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.isLoading = true;
        await this.getPaques();
      }
    },
    async deletepaque(id) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce paquet?")) {
        try {
          await axiosInstance.delete(`paquets/${id}`);
          alert("Paque supprimée avec succès !");
          this.handlepaqueDeleted(id);
        } catch (error) {
          this.$logErrorToBackend("Error deleting paque :", error.message);
          alert("Erreur lors de la suppression de paque.");
        }
      }
    },
    handlepaqueDeleted(deletedpaqueId) {
      this.paques = this.paques.filter(paque => paque.id_paque !== deletedpaqueId);

      if (this.paques.length === 0 && this.currentPage > 1) {
        this.changePage(this.currentPage - 1);
      } else {
        // Sinon, rechargez la page actuelle
        this.getPaques();
      }
    }
  }
};
</script>

<style scoped>
.category-box {
  transition: transform 0.3s ease-in-out;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 auto;
}

.category-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.swiper-container {
  height: 400px;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom {
  font-family: 'Arabswell', serif;
  color: #666;
  margin-bottom: 0.1rem;
}

.bs-component {
  display: flex;
  align-items: center;
}
</style>